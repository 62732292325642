export const ResguardosMapper = aResguardos => {
  const mapResguardos = oServerDocument => ({
    id: oServerDocument.resId,
    serie: oServerDocument.resSerie,
    code: oServerDocument.resNro,
    total: oServerDocument.resTotRet,
    date: oServerDocument.resFch,
    partner: {
      id: oServerDocument.socId,
      name: oServerDocument.socNom,
    },
    currency: {
      id: oServerDocument.monId,
      change: oServerDocument.resTC,
    },
    expiration: oServerDocument.resFchVenc,
    observations: oServerDocument.resRef,
    cae: {
      status: +oServerDocument.enviadoDGI ? true : false,
      number: oServerDocument.caeNro,
      link: oServerDocument.caeLinkVerif,
    },
    lines: ResguardosLineMapper(oServerDocument.lineas),
    isUpdatable: false,
  });

  if (Array.isArray(aResguardos)) return aResguardos.map(mapResguardos);
  if ((typeof aResguardos).toString() === "object") return mapResguardos(aResguardos);
  return null;
};

export const ResguardosUnMapper = aResguardos => {
  const unMapResguardos = oItem => ({
    resId: oItem.id || "",
    resNro: oItem.code ?? 0,
    resTotRet: oItem.totalWithheldAmount,
    resFch: oItem.date,
    socId: oItem.partner?.id,
    resFpago: "",
    resAnu: 0,
    monId: oItem.currency?.id || 0,
    resTC: oItem.currency?.change || 0,
    resRef: oItem.observations || "",
    resFchVenc: oItem.expiration,
    lineas: ResguardosLineUnMapper(oItem.lines),
  });

  if (Array.isArray(aResguardos)) return aResguardos.map(unMapResguardos);
  if ((typeof aResguardos).toString() === "object") return unMapResguardos(aResguardos);
  return null;
};

export const ResguardosLineMapper = aLines => {
  if (!aLines) return null;
  const mapResguardosLine = item => ({
    id: item.resLinNro,
    date: item.resLinFch,
    document: {
      id: item.docId,
    },
    amount: item.resLinImp,
    currency: {
      id: item.monId,
      change: item.resLinTC,
    },
    rate: item.resLinTasa,
    taxableAmount: item.resLinMonImp,
    withheldAmount: item.resLinMonRet,
    taxType: {
      id: item.tpoRetId,
    },
    description: item.tpoRetDesc,
    tax: item.resImpuesto,
  });

  if (Array.isArray(aLines)) return aLines.map(mapResguardosLine);
  if ((typeof aLines).toString() === "object") return mapResguardosLine(aLines);
  return null;
};

export const ResguardosLineUnMapper = aLines => {
  if (!aLines) return null;
  const mapOrdersLine = oLine => ({
    reslinfch: oLine.date,
    docid: oLine.document?.id,
    reslinimp: oLine.amount,
    monid: oLine.currency?.value,
    reslintc: oLine.currency?.change,
    reslinmonimp: oLine.taxableAmount,
    reslintasa: oLine.rate,
    reslinmonret: oLine.withheldAmount,
    resimpuesto: oLine.tax ?? "",
    tporetid: oLine.taxType?.value,
  });

  if (Array.isArray(aLines)) return aLines.map(mapOrdersLine);
  if ((typeof aLines).toString() === "object") return mapOrdersLine(aLines);
  return null;
};

export const ResguardosCsvResponseMapper = oServerData => {
  const oTitles = oServerData;
  const aRecords = oServerData.lineas;
  delete oTitles.lineas;
  const aTitles = [];
  const oExample = aRecords[0] ?? null;
  if (!oExample)
    return {
      titles: aTitles,
      records: [],
    };
  const aKeys = Object.keys(oExample).map((key) => key);
  const aLabels = Object.keys(oTitles).map((key) => oTitles[key]);
  for (let nIndex = 0; nIndex < aKeys.length; nIndex++) {
    aTitles.push({
      key: aKeys[nIndex],
      label: aLabels[nIndex],
    });
  }
  return {
    headers: aTitles,
    records: aRecords,
  };
};

export const ResguardosRelationsMapper = aServerItems => {
  const mapper = oServerItem => ({
    id: oServerItem.id,
    type: oServerItem.tipo,
    date: oServerItem.fecha,
    identifier: oServerItem.nroInterno,
    cae: oServerItem.cae,
    amount: oServerItem.importe,
  });

  if (Array.isArray(aServerItems)) return aServerItems.map(mapper);
  if ((typeof aServerItems).toString() === "object") return mapper(aServerItems);
  return null;
};

export const ResguardosGetLineInfoMapper = aItems => {
  const mapper = item => ({
    resfchvenc: item.date,
    docid: item.document?.id ?? "0",
    reslinimp: item.amount,
    monid: item.currency?.value,
    reslintc: item.currency?.change,
    reslinmonimp: item.taxableAmount,
    reslintasa: item.rate,
    reslinmonret: item.withheldAmount,
    resimpuesto: item.tax ?? "",
    tporetid: item.taxType?.value,
  });

  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const ResguardosGetLineInfoUnMapper = aItems => {
  const mapper = item => ({
    date: item.resLinFch,
    document: {
      id: item.docId,
    },
    amount: item.resLinImp,
    currency: {
      id: item.monId,
    },
    rate: item.resLinTasa,
    taxableAmount: item.resLinMonImp,
    withheldAmount: item.resLinMonRet,
    taxType: {
      id: item.tpoRetId,
    },
  });

  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const ResguardosTaxTypeUnMapper = aItems => {
  const mapper = item => ({
    value: item.tpoRetId,
    label: item.tpoRetDesc,
    // TODO: Check if these fields are necessary
    // tpoRetCLinea
    // tpoRetForm
  });

  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};
