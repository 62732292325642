import oDayJs from "dayjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiCallGetAllDocuments,
  apiCallDocumentsFind,
  apiCallDocumentsFindLines,
  apiCallDocumentsSave,
  apiCallSendDocumentToDGI,
  apiCallDocumentsExportToCsv,
  apiCallDocumentDownload,
  apiCallDocumentCancel,
  apiCallDocumentGetRelations,
  apiCallDocumentDeleteRelations,
  apiCallDocumentGetDocumentsRelations,
  apiCallDocumentSaveRelations,
  apiCallDocumentsUpdate,
  apiCallDocumentPrint,
  apiCallDocumentsSuggest,
  apiCallDocumentsImportFromFile,
} from "../api/DocumentsServices";
import {
  actionManageMessage,
  actionShowLoading,
  actionDismissLoading,
  actionMessage,
} from "redux/shared/SharedSlice";
import { DocumentsCsvResponseMapper, DocumentsLineMapper, DocumentsMapper, DocumentsRelationsMapper, DocumentsUnMapper } from "./DocumentsMapper";

import { DATES_FORMATS } from "config/constants/Dates";
import { CURRENCY_VALUES, CURRENCY_CODES } from "config/constants/Currency";
import { DOCUMENT_STATUS_CODES, DOCUMENT_STATUS_VALUES, DOCUMENT_TAX_STATUS_CODES, DOCUMENT_TAX_STATUS_VALUES } from "../constants/Documents";

import { PriceArticlesMapper } from "pages/prices";
import { apiCallPricesFind } from "pages/prices/api/PriceServices";
import { CONSTANTS_MESSAGE } from "config/constants/Message";
import { apiCallGetCurrencyChange } from "api/CommonApiServices";
import { apiCallGetAllLots } from "pages/lots/api/LotServices";
import { LotsMapper } from "pages/lots";

const oInitialState = {
  aDocuments: [],
  nRecords: null,
  bStatus: false,
  bStatusSend: false,
  oDocument: null,
  aLines: null,
  aPrices: null,
  bLot: false,
  oLot: null,
  oType: null,

  oDataExport: null,
  sFile: null,
  sPrintFile: null,

  nPages: null,
  nPage: 1,
  sTaxNro: "",
  oTaxStatus: DOCUMENT_TAX_STATUS_VALUES.find(current => current.value === DOCUMENT_TAX_STATUS_CODES.ALL),
  nCode: 0,
  oPartner: 0,
  oStatus: DOCUMENT_STATUS_VALUES.find(current => current.value === DOCUMENT_STATUS_CODES.NONE),
  oCurrency: CURRENCY_VALUES.find(current => current.value === CURRENCY_CODES.NONE),
  dDateTo: oDayJs().toString(),
  dDateFrom: oDayJs().toString(),

  nCurrencyChange: null,

  aRelations: [],
  bStatusRelations: false,
  aDocumentsRelations: [],
  aSuggestions: [],
};

export const actionDocumentsGetAll = createAsyncThunk("documents/getAllDocuments", async(args, oThunk) => {
  try {
    const { DocumentsSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      nro: (oState?.nCode && !Number.isNaN(+oState?.nCode)) ? Number.parseInt(oState.nCode) : 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      monid: oState.oCurrency?.value || 0,
      docestado: oState.oStatus?.value || 0,
      socid: oState.oPartner?.id || 0,
      nroDGI: oState.sTaxNro || "",
      enviadoDGI: oState.oTaxStatus?.value ?? 2,
      doctipo: oState.oType?.id || 0,
    };
    const oSuccess = await apiCallGetAllDocuments(oData);
    return { data: DocumentsMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsFind = createAsyncThunk("documents/find", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentsFind(nId);
    return DocumentsMapper(oSuccess.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsSave = createAsyncThunk("documents/save", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentsSave(DocumentsUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Documento generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsUpdate = createAsyncThunk("documents/update", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentsUpdate(oData.id, DocumentsUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Documento actualizado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsFindLines = createAsyncThunk("documents/findLines", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentsFindLines(nId);
    return DocumentsLineMapper(oSuccess?.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsGetPricesByList = createAsyncThunk("documents/getPricesByList", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallPricesFind(nId);
    return PriceArticlesMapper(oSuccess?.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsGetCurrencyChange = createAsyncThunk("documents/getCurrencyChange", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetCurrencyChange(nId);
    if (!oSuccess?.data) return null;
    return oSuccess.data.monTC;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsSendToDGI = createAsyncThunk("documents/sandToDGI", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallSendDocumentToDGI(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Documento envíado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsDownload = createAsyncThunk("documents/download", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentDownload(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Documento descargado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oSuccess.data?.archivo;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsPrint = createAsyncThunk("documents/print", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentPrint(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Documento cargado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oSuccess.data?.informe;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsExportToCsv = createAsyncThunk("documents/exportToCsv", async(arg, oThunk) => {
  try {
    const { DocumentsSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      nro: (oState?.nCode && !Number.isNaN(+oState?.nCode)) ? Number.parseInt(oState.nCode) : 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      monid: oState.oCurrency?.value || 0,
      docestado: oState.oStatus?.value || 0,
      socid: oState.oPartner?.id || 0,
      nroDGI: oState.sTaxNro || "",
      enviadoDGI: oState.oTaxStatus?.value ?? 2,
    };
    const oSuccess = await apiCallDocumentsExportToCsv(oData);
    return { data: DocumentsCsvResponseMapper(oSuccess.data), records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsFindLot = createAsyncThunk("documents/findLot", async(sLot, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oData = {
      loteId: "",
      estloteid: 0,
      pagina: 1,
      loteCod: sLot,
      artid: 0,
    };
    const oSuccess = await apiCallGetAllLots(oData);
    const oLot = oSuccess?.data?.length > 0 ? oSuccess?.data[0] : null;
    return LotsMapper(oLot);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsCancel = createAsyncThunk("documents/cancel", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentCancel(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Documento anulado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionDocumentsGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsGetRelations = createAsyncThunk("documents/getRelations", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentGetRelations(nId);
    return DocumentsRelationsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsDeleteRelations = createAsyncThunk("documents/deleteRelations", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentDeleteRelations({
      DocId: oData.id,
      DocIDDto: oData.relation.id,
    });
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Relación eliminada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionDocumentsGetRelations(oData.id));
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsSaveRelations = createAsyncThunk("documents/saveRelations", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentSaveRelations({
      docId: oData.id,
      docIDDto: oData.relationId,
      docTipo: oData.type,
      importe: oData.amount,
    });
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Relación agregada correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionDocumentsGetRelations(oData.id));
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsGetDocumentsRelations = createAsyncThunk("documents/getDocumentsRelations", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentGetDocumentsRelations({
      tipo: oData.type,
      nroInterno: oData.identifier,
      cae: "",
    });
    return DocumentsRelationsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionDocumentsSuggest = createAsyncThunk("documents/documentsSuggest", async(oData, oThunk) => {
  try {
    const oSuccess = await apiCallDocumentsSuggest({ tipo: oData.type, nroInterno: oData.identifier, cae: "" });
    return DocumentsRelationsMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionDocumentsImportFromFile = createAsyncThunk("documents/importFromFile", async(oData, oThunk) => {
  try {
    const oSuccess = await apiCallDocumentsImportFromFile(oData);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Archivo enviado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const DocumentsSlice = createSlice({
  name: "DocumentsSlice",
  initialState: oInitialState,
  reducers: {
    actionDocumentsClean: oState => {
      oState.bStatus = false;
      oState.bStatusSend = false;
      oState.oDocument = null;
      oState.aLines = null;
      oState.sFile = null;
      oState.sPrintFile = null;
      oState.oDataExport = null;
      oState.bLot = false;
      oState.oLot = null;
      oState.aRelations = [];
      oState.bStatusRelations = false;
      oState.aDocumentsRelations = [];
    },
    actionDocumentsCleanLot: oState => {
      oState.bLot = false;
      oState.oLot = null;
    },
    actionDocumentsChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionDocumentsChangeCode: (oState, oAction) => {
      oState.nCode = oAction.payload;
    },
    actionDocumentsChangeTaxNro: (oState, oAction) => {
      oState.sTaxNro = oAction.payload;
    },
    actionDocumentsChangePartner: (oState, oAction) => {
      oState.oPartner = oAction.payload;
    },
    actionDocumentsChangeCurrency: (oState, oAction) => {
      oState.oCurrency = oAction.payload;
    },
    actionDocumentsChangeType: (oState, oAction) => {
      oState.oType = oAction.payload;
    },
    actionDocumentsChangeStatus: (oState, oAction) => {
      oState.oStatus = oAction.payload;
    },
    actionDocumentsChangeTaxStatus: (oState, oAction) => {
      oState.oTaxStatus = oAction.payload;
    },
    actionDocumentsChangeDateFrom: (oState, oAction) => {
      oState.dDateFrom = oAction.payload;
    },
    actionDocumentsChangeDateTo: (oState, oAction) => {
      oState.dDateTo = oAction.payload;
    },
    actionDocumentsCleanRelations: oState => {
      oState.bStatusRelations = false;
      oState.aDocumentsRelations = [];
    },
    actionDocumentsCleanStatus: oState => {
      oState.bStatus = false;
    },
    actionDocumentsCleanPrintFile: oState => {
      oState.sPrintFile = null;
    },
    actionDocumentsCleanSuggestions: oState => {
      oState.aSuggestions = [];
    },
    actionDocumentsCleanFilters: oState => {
      oState.sPrintFile = null;
      oState.nPages = null;
      oState.nPage = 1;
      oState.sTaxNro = "";
      oState.oTaxStatus = DOCUMENT_TAX_STATUS_VALUES.find(current => current.value === DOCUMENT_TAX_STATUS_CODES.ALL);
      oState.nCode = 0;
      oState.oPartner = 0;
      oState.oStatus = DOCUMENT_STATUS_VALUES.find(current => current.value === DOCUMENT_STATUS_CODES.NONE);
      oState.oCurrency = CURRENCY_VALUES.find(current => current.value === CURRENCY_CODES.NONE);
      oState.dDateTo = oDayJs().toString();
      oState.dDateFrom = oDayJs().toString();
    },
  },
  extraReducers: builder => {
    builder.addCase(actionDocumentsGetAll.fulfilled, (oState, oAction) => {
      oState.aDocuments = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionDocumentsExportToCsv.fulfilled, (oState, oAction) => {
      oState.oDataExport = oAction.payload.data;
    });
    builder.addCase(actionDocumentsFind.fulfilled, (oState, oAction) => {
      oState.oDocument = oAction.payload;
    });
    builder.addCase(actionDocumentsFindLines.fulfilled, (oState, oAction) => {
      oState.aLines = oAction.payload;
    });
    builder.addCase(actionDocumentsGetPricesByList.fulfilled, (oState, oAction) => {
      oState.aPrices = oAction.payload;
    });
    builder.addCase(actionDocumentsGetCurrencyChange.fulfilled, (oState, oAction) => {
      oState.nCurrencyChange = oAction.payload;
    });
    builder.addCase(actionDocumentsSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionDocumentsSendToDGI.fulfilled, (oState, oAction) => {
      oState.bStatusSend = oAction.payload;
    });
    builder.addCase(actionDocumentsDownload.fulfilled, (oState, oAction) => {
      oState.sFile = oAction.payload;
    });
    builder.addCase(actionDocumentsPrint.fulfilled, (oState, oAction) => {
      oState.sPrintFile = oAction.payload;
    });
    builder.addCase(actionDocumentsFindLot.fulfilled, (oState, oAction) => {
      oState.bLot = true;
      oState.oLot = oAction.payload;
    });
    builder.addCase(actionDocumentsGetRelations.fulfilled, (oState, oAction) => {
      oState.aRelations = oAction.payload;
    });
    builder.addCase(actionDocumentsGetDocumentsRelations.fulfilled, (oState, oAction) => {
      oState.aDocumentsRelations = oAction.payload;
    });
    builder.addCase(actionDocumentsSaveRelations.fulfilled, (oState, oAction) => {
      oState.bStatusRelations = oAction.payload;
    });
    builder.addCase(actionDocumentsUpdate.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionDocumentsSuggest.fulfilled, (oState, oAction) => {
      oState.aSuggestions = oAction.payload;
    });
  },
});

export const {
  actionDocumentsClean,
  actionDocumentsCleanLot,
  actionDocumentsChangePage,
  actionDocumentsChangeCode,
  actionDocumentsChangeTaxNro,
  actionDocumentsChangePartner,
  actionDocumentsChangeCurrency,
  actionDocumentsChangeType,
  actionDocumentsChangeStatus,
  actionDocumentsChangeTaxStatus,
  actionDocumentsChangeDateFrom,
  actionDocumentsChangeDateTo,
  actionDocumentsCleanRelations,
  actionDocumentsCleanStatus,
  actionDocumentsCleanPrintFile,
  actionDocumentsCleanSuggestions,
  actionDocumentsCleanFilters,
} = DocumentsSlice.actions;

export const selectDocumentsState = ({ DocumentsSlice: oState }) => oState;

export default DocumentsSlice.reducer;
