import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { SharedContext } from "context/SharedContext";

const Login = React.lazy(() => import(/*webpackChunkName: "Login" */ "pages/login/LoginPage"));
const Home = React.lazy(() => import(/*webpackChunkName: "Home" */ "pages/home/Home"));
const UsersList = React.lazy(() => import(/*webpackChunkName: "UsersList" */ "pages/users"));
const UsersAdd = React.lazy(() => import(/*webpackChunkName: "UsersAdd" */ "pages/users/UsersAdd"));
const UsersUpdate = React.lazy(() => import(/*webpackChunkName: "UsersUpdate" */ "pages/users/UsersUpdate"));
const UsersProfile = React.lazy(() => import(/*webpackChunkName: "UsersProfile" */ "pages/users/UsersProfile"));
const ChangePassword = React.lazy(() => import(/*webpackChunkName: "ChangePassword" */ "pages/users/ChangePassword"));
const TasksList = React.lazy(() => import(/*webpackChunkName: "TasksList" */ "pages/tasks"));
const FamiliesList = React.lazy(() => import(/*webpackChunkName: "FamiliesList" */ "pages/families"));
const LocationsList = React.lazy(() => import(/*webpackChunkName: "LocationsList" */ "pages/locations"));
const PricesList = React.lazy(() => import(/*webpackChunkName: "PricesList" */ "pages/prices"));
const PricesReport = React.lazy(() => import(/*webpackChunkName: "PricesReport" */ "pages/prices/PricesReport"));
const RolesList = React.lazy(() => import(/*webpackChunkName: "RolesList" */ "pages/roles"));
const RolesAdd = React.lazy(() => import(/*webpackChunkName: "RolesAdd" */ "pages/roles/RolesAdd"));
const RolesUpdate = React.lazy(() => import(/*webpackChunkName: "RolesUpdate" */ "pages/roles/RolesUpdate"));
const PartnersGroupList = React.lazy(() => import(/*webpackChunkName: "PartnersGroupList" */ "pages/partners/groups"));
const PartnersGroupUpdate = React.lazy(() => import(/*webpackChunkName: "PartnersGroupUpdate" */ "pages/partners/groups/PartnersGroupUpdate"));
const PartnersList = React.lazy(() => import(/*webpackChunkName: "PartnersList" */ "pages/partners"));
const PartnersAdd = React.lazy(() => import(/*webpackChunkName: "PartnersAdd" */ "pages/partners/PartnersAdd"));
const PartnersUpdate = React.lazy(() => import(/*webpackChunkName: "PartnersUpdate" */ "pages/partners/PartnersUpdate"));
const ArticlesList = React.lazy(() => import(/*webpackChunkName: "ArticlesList" */ "pages/articles"));
const ArticlesAdd = React.lazy(() => import(/*webpackChunkName: "ArticlesAdd" */ "pages/articles/ArticlesAdd"));
const ArticlesUpdate = React.lazy(() => import(/*webpackChunkName: "ArticlesUpdate" */ "pages/articles/ArticlesUpdate"));
const DocumentTypesList = React.lazy(() => import(/*webpackChunkName: "DocumentTypesList" */ "pages/documentTypes"));
const IndicatorsList = React.lazy(() => import(/*webpackChunkName: "IndicatorsList" */ "pages/indicators"));
const UnitsList = React.lazy(() => import(/*webpackChunkName: "UnitsList" */ "pages/units"));
const DocumentsList = React.lazy(() => import(/*webpackChunkName: "DocumentsList" */ "pages/documents"));
const DocumentsAdd = React.lazy(() => import(/*webpackChunkName: "DocumentsAdd" */ "pages/documents/DocumentsAdd"));
const DocumentsUpdate = React.lazy(() => import(/*webpackChunkName: "DocumentsUpdate" */ "pages/documents/DocumentsUpdate"));
const ReceiptsList = React.lazy(() => import(/*webpackChunkName: "ReceiptsList" */ "pages/receipts"));
const ReceiptsAdd = React.lazy(() => import(/*webpackChunkName: "ReceiptsAdd" */ "pages/receipts/ReceiptsAdd"));
const ReceiptsUpdate = React.lazy(() => import(/*webpackChunkName: "ReceiptsUpdate" */ "pages/receipts/ReceiptsUpdate"));
const ResguardosList = React.lazy(() => import(/*webpackChunkName: "ResguardosList" */ "pages/resguardos"));
const ResguardosAdd = React.lazy(() => import(/*webpackChunkName: "ResguardosAdd" */ "pages/resguardos/ResguardosAdd"));
const ResguardosUpdate = React.lazy(() => import(/*webpackChunkName: "ResguardosUpdate" */ "pages/resguardos/ResguardosUpdate"));
const LotsList = React.lazy(() => import(/*webpackChunkName: "LotsList" */ "pages/lots/LotsList"));
const CashRegisterList = React.lazy(() => import(/*webpackChunkName: "CashRegisterList" */ "pages/cash_register/CashRegisterList"));
const CurrentCashRegister = React.lazy(() => import(/*webpackChunkName: "CurrentCashRegister" */ "pages/cash_register/CurrentCashRegister"));
const ProductionList = React.lazy(() => import(/*webpackChunkName: "ProductionList" */ "pages/productions"));
const ProductionAdd = React.lazy(() => import(/*webpackChunkName: "ProductionAdd" */ "pages/productions/ProductionAdd"));
const ProductionUpdate = React.lazy(() => import(/*webpackChunkName: "ProductionUpdate" */ "pages/productions/ProductionUpdate"));
const Reports = React.lazy(() => import(/*webpackChunkName: "Reports" */ "pages/reports"));
const SellersList = React.lazy(() => import(/*webpackChunkName: "SellersList" */ "pages/sellers"));
const Config = React.lazy(() => import(/*webpackChunkName: "Config" */ "pages/config"));

const aPublicRoutes = [
  { key: "login", path: "/", element: <Login /> },
  { key: "notFound", path: "*", element: <Login /> },
];

export const aPrivateRoutes = [
  { key: "home", path: "/", element: <Home /> },
  { key: "users", path: "/users", element: <UsersList /> },
  { key: "usersAdd", path: "/users/new", element: <UsersAdd /> },
  { key: "usersUpdate", path: "/users/:id", element: <UsersUpdate /> },
  { key: "usersProfile", path: "/profile", element: <UsersProfile /> },
  { key: "changePassword", path: "/change-password", element: <ChangePassword /> },
  { key: "tasks", path: "/tasks", element: <TasksList /> },
  { key: "families", path: "/families", element: <FamiliesList /> },
  { key: "locations", path: "/locations", element: <LocationsList /> },
  { key: "prices", path: "/prices", element: <PricesList /> },
  { key: "pricesReport", path: "/prices/report/:id", element: <PricesReport /> },
  { key: "roles", path: "/roles", element: <RolesList /> },
  { key: "rolesAdd", path: "/roles/new", element: <RolesAdd /> },
  { key: "rolesUpdate", path: "/roles/:id", element: <RolesUpdate /> },
  { key: "partnersGroup", path: "/groups/partners", element: <PartnersGroupList /> },
  { key: "PartnersGroupUpdate", path: "/groups/partners/:id", element: <PartnersGroupUpdate /> },
  { key: "partners", path: "/partners", element: <PartnersList /> },
  { key: "partnersAdd", path: "/partners/new", element: <PartnersAdd /> },
  { key: "partnersUpdate", path: "/partners/:id", element: <PartnersUpdate /> },
  { key: "articles", path: "/articles", element: <ArticlesList /> },
  { key: "articlesAdd", path: "/articles/new", element: <ArticlesAdd /> },
  { key: "ArticlesUpdate", path: "/articles/:id", element: <ArticlesUpdate /> },
  { key: "documentTypes", path: "/document/types", element: <DocumentTypesList /> },
  { key: "indicators", path: "/indicators", element: <IndicatorsList /> },
  { key: "units", path: "/units", element: <UnitsList /> },
  { key: "documents", path: "/documents", element: <DocumentsList /> },
  { key: "documentsAdd", path: "/documents/new", element: <DocumentsAdd /> },
  { key: "documentsUpdate", path: "/documents/:id", element: <DocumentsUpdate /> },
  { key: "receipts", path: "/receipts", element: <ReceiptsList /> },
  { key: "receiptsAdd", path: "/receipts/new", element: <ReceiptsAdd /> },
  { key: "receiptsUpdate", path: "/receipts/:id", element: <ReceiptsUpdate /> },
  { key: "resguardos", path: "/resguardos", element: <ResguardosList /> },
  { key: "resguardosAdd", path: "/resguardos/new", element: <ResguardosAdd /> },
  { key: "resguardosUpdate", path: "/resguardos/:id", element: <ResguardosUpdate /> },
  { key: "lots", path: "/lots", element: <LotsList /> },
  { key: "cashRegister", path: "/cash_register", element: <CashRegisterList /> },
  { key: "currentCashRegister", path: "/current/cash_register", element: <CurrentCashRegister /> },
  { key: "productions", path: "/productions", element: <ProductionList /> },
  { key: "productionsAdd", path: "/productions/new", element: <ProductionAdd /> },
  { key: "productionUpdate", path: "/productions/:id", element: <ProductionUpdate /> },
  { key: "reports", path: "/reports/:type", element: <Reports /> },
  { key: "sellers", path: "/sellers", element: <SellersList /> },
  { key: "config", path: "/config", element: <Config /> },
  { key: "notFound", path: "*", element: <Home /> },
];

export function RouteConfig() {
  const { bIsAuthenticated } = useContext(SharedContext);

  return (
    <Routes>
      {bIsAuthenticated
        ? aPrivateRoutes.map(oRoute => <Route {...oRoute} key={oRoute.key} />)
        : aPublicRoutes.map(oRoute => <Route {...oRoute} key={oRoute.key} />)}
    </Routes>
  );
}
