import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiDownload, FiSearch, FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsFilePdf } from "react-icons/bs";
import { BiExport } from "react-icons/bi";
import { FaBan } from "react-icons/fa6";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import oDayJs from "dayjs";


import {
  ScreenContainer,
  Text,
  Input,
  Table,
  ComboBox,
  DateInput,
  PartnerSuggestions,
  Dropdown,
  Button,
  ConfirmationModal,
} from "components";
import CustomModal from "components/CustomModal";
import { CastDate, CastFloat } from "utils/SharedUtils";
import { CURRENCY_VALUES } from "config/constants/Currency";

import { BUTTON_CONFIG } from "config/constants/Button";
import ReceiptsLang from "./lang";
import {
  actionReceiptsChangeCurrency,
  actionReceiptsChangeDateFrom,
  actionReceiptsChangeDateTo,
  actionReceiptsChangeCode,
  actionReceiptsChangePage,
  actionReceiptsClean,
  actionReceiptsGetAll,
  actionReceiptsChangePartner,
  selectReceiptsState,
  actionReceiptsChangeTaxStatus,
  actionReceiptsChangeDgiNumber,
  actionReceiptsChangeType,
  actionReceiptsExportToCsv,
  actionReceiptsDownload,
  actionReceiptsSendToDGI,
  actionReceiptsCancel,
  actionReceiptsCleanFilters,
} from "./redux";
import { RECEIPT_TAX_STATUS_VALUES, RECEIPT_TYPES_CODES, RECEIPT_TYPES_VALUES } from "./constants/Receipts";

const ReceiptsList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();
  const [oReSend, setReSend] = useState(null);
  const [bExport, setExport] = useState(false);
  const [sOpenRow, setOpenRow] = useState(null);
  const [oDownload, setDownload] = useState(null);

  const [oCancel, setCancel] = useState(null);

  const aOptionButtons = [
    {
      onClick: () => setExport(true),
      label: "Exportar",
    },
    {
      onClick: () => onNavigate({ type: RECEIPT_TYPES_CODES.PAY }),
      label: "Nuevo Pago",
    },
    {
      onClick: () => onNavigate({ type: RECEIPT_TYPES_CODES.CHARGE }),
      label: "Nuevo Cobro",
    },
  ];

  const aNewButtons = [
    {
      onClick: () => onNavigate(),
      label: "Nuevo",
    },
    {
      onClick: () => onNavigate({ type: RECEIPT_TYPES_CODES.PAY }),
      label: "Pago",
    },
    {
      onClick: () => onNavigate({ type: RECEIPT_TYPES_CODES.CHARGE }),
      label: "Cobro",
    },
  ];

  const {
    aReceipts,
    nPage,
    nCode,
    sDgiNumber,
    nPages,
    nRecords,
    dDateTo,
    dDateFrom,
    oTaxStatus,
    oCurrency,
    oType,
    oDataExport,
    bStatusSend,
    sFile,
  } = useSelector(selectReceiptsState);

  useEffect(() => {
    oDispatch(actionReceiptsGetAll());
    return () => {
      oDispatch(actionReceiptsCleanFilters());
    };
  }, []);

  useEffect(() => {
    if (bStatusSend) {
      oDispatch(actionReceiptsGetAll());
      oDispatch(actionReceiptsClean());
    }
  }, [bStatusSend]);

  useEffect(() => {
    if (sFile) {
      printJS({
        base64: true,
        printable: sFile,
        modalMessage: "Cargando...",
        documentTitle: oDownload.id,
        onPrintDialogClose: () => {
          setDownload(null);
          oDispatch(actionReceiptsClean());
        },
      });
    }
  }, [sFile]);

  const onChangeTimeout = (oState, oData) => {
    if (!oData) { return oState; }
    if (oState) { clearTimeout(oState); }
    return oData;
  };

  const [, setTimer] = useReducer(onChangeTimeout, null);

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionReceiptsChangePage(nSelectedPage));
    oDispatch(actionReceiptsGetAll());
  };

  const onChangeCode = (sNewCode) => {
    oDispatch(actionReceiptsChangeCode(sNewCode.trim()));
    setTimer(setTimeout(() => {
      oDispatch(actionReceiptsChangePage(1));
      oDispatch(actionReceiptsGetAll());
    }, 800));
  };

  const onChangeDgiNumber = (sNewCode) => {
    oDispatch(actionReceiptsChangeDgiNumber(sNewCode.trim()));
    setTimer(setTimeout(() => {
      oDispatch(actionReceiptsChangePage(1));
      oDispatch(actionReceiptsGetAll());
    }, 800));
  };

  const onChangePartner = (oPartner) => {
    oDispatch(actionReceiptsChangePartner(oPartner));
    oDispatch(actionReceiptsChangePage(1));
    oDispatch(actionReceiptsGetAll());
  };

  const onChangeDateFrom = (dDate) => {
    oDispatch(actionReceiptsChangeDateFrom(oDayJs(dDate).toString()));
    oDispatch(actionReceiptsChangePage(1));
    oDispatch(actionReceiptsGetAll());
  };

  const onChangeDateTo = (dDate) => {
    oDispatch(actionReceiptsChangeDateTo(oDayJs(dDate).toString()));
    oDispatch(actionReceiptsChangePage(1));
    oDispatch(actionReceiptsGetAll());
  };

  const onChangeCurrency = (oCurrency) => {
    oDispatch(actionReceiptsChangeCurrency(oCurrency));
    oDispatch(actionReceiptsChangePage(1));
    oDispatch(actionReceiptsGetAll());
  };

  const onChangeType = (oType) => {
    oDispatch(actionReceiptsChangeType(oType));
    oDispatch(actionReceiptsChangePage(1));
    oDispatch(actionReceiptsGetAll());
  };

  const onChangeTaxStatus = (oStatus) => {
    oDispatch(actionReceiptsChangeTaxStatus(oStatus));
    oDispatch(actionReceiptsChangePage(1));
    oDispatch(actionReceiptsGetAll());
  };

  const onExportToCsv = () => {
    setExport(false);
    oDispatch(actionReceiptsExportToCsv());
  };

  const onReSendOrOpen = (event, oItem) => {
    event.stopPropagation();
    if (oItem.cae?.status) {
      if (sOpenRow === oItem.id)
        return setOpenRow(null);
      return setOpenRow(oItem.id);
    }
    setReSend(oItem);
  };

  const onConfirmReSend = () => {
    oDispatch(actionReceiptsSendToDGI(oReSend.id));
    setReSend(null);
  };

  const onConfirmDownload = () => {
    oDispatch(actionReceiptsDownload(oDownload.id));
  };

  const onDownload = (event, oItem) => {
    event.stopPropagation();
    if (oItem.cae.status) return setDownload(oItem);
  };

  const onView = (oItem) => oNavigator(`/receipts/${oItem.id}`);
  const onNavigate = (oOptions) => oNavigator("/receipts/new", { state: oOptions });

  const onCancel = (oItem) => {
    if (!oCancel) return setCancel(oItem);
    oDispatch(actionReceiptsCancel(oCancel.id));
    setCancel(null);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          {ReceiptsLang.Labels.listReceipts}
        </Text>
        <div className="gap-2 hidden md:flex">
          <Button
            label="Exportar"
            containerClassName="ml-auto"
            onClick={() => setExport(true)}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<BiExport className="text-white ml-2 text-lg" />}
          />
          <Dropdown
            label="Nuevo"
            items={aNewButtons}
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
          />
        </div>
        <div className="gap-2 flex md:hidden">
          <Dropdown
            label="Opciones"
            items={aOptionButtons}
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={nCode}
            floatLabel={false}
            onChange={onChangeCode}
            placeholder={ReceiptsLang.Fields.code}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sDgiNumber}
            floatLabel={false}
            onChange={onChangeDgiNumber}
            placeholder={ReceiptsLang.Fields.cfeNumber}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Estado</span>
          </div>
          <ComboBox
            size="sm"
            selected={oTaxStatus}
            onChange={onChangeTaxStatus}
            options={RECEIPT_TAX_STATUS_VALUES}
            emptyLabel={ReceiptsLang.Fields.status}
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-full">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400 z-10" />
          </div>
          <PartnerSuggestions
            onSelect={onChangePartner}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Desde</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={onChangeDateFrom}
            placeholder={ReceiptsLang.Labels.dateFrom}
            containerClassName="rounded-lg overflow-hidden ml-12"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Hasta</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={onChangeDateTo}
            placeholder={ReceiptsLang.Labels.dateTo}
            containerClassName="rounded-lg overflow-hidden ml-12"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <ComboBox
            selected={oCurrency}
            onChange={onChangeCurrency}
            emptyLabel={ReceiptsLang.Fields.currency}
            options={CURRENCY_VALUES}
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <ComboBox
            selected={oType}
            onChange={onChangeType}
            emptyLabel={ReceiptsLang.Fields.type}
            options={RECEIPT_TYPES_VALUES}
          />
        </div>
      </section>
      <section className="py-5">
        <Table data-testid={"ReceiptsTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="text-center" />
              <Table.Col scope="col" className="px-6 py-3 text-center">
                {ReceiptsLang.Fields.nro}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ReceiptsLang.Fields.date}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3">
                {ReceiptsLang.Fields.headline}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ReceiptsLang.Fields.type}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {ReceiptsLang.Fields.currency}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                {ReceiptsLang.Fields.total}
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                {ReceiptsLang.Fields.taxStatus}
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aReceipts?.length > 0 ? (
              aReceipts.map((oItem, index) => (
                <React.Fragment key={`${oItem.id}_${index}`} >
                  <Table.Row onClick={() => onView(oItem)}>
                    <Table.Col scope="row">
                      <div className="pl-6 flex justify-center cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        onCancel(oItem);
                      }}>
                        <FaBan className="text-lg text-red-400 dark:text-red-400" />
                      </div>
                    </Table.Col>
                    <Table.Col
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                    >
                      {oItem.code}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {CastDate(oItem.date)}
                    </Table.Col>
                    <Table.Col className="px-6 py-4">
                      {oItem.partner?.name || "-"}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem?.type?.name || "S/T"}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                      {oItem?.currency?.code || "S/M"}
                    </Table.Col>
                    <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                      {CastFloat(oItem.total)}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 flex items-center justify-center gap-3">
                      <div className="flex justify-center cursor-pointer" onClick={e => onReSendOrOpen(e, oItem)}>
                        {oItem.cae?.status
                          ? <BsFilePdf className="text-lg text-green-400 dark:text-green-400" />
                          : <FiSend className="text-lg text-red-400 dark:text-red-400" />
                        }
                      </div>
                    </Table.Col>
                  </Table.Row>
                  {
                    sOpenRow === oItem.id &&
                    <Table.Row>
                      <Table.Col className="px-6 py-4 text-center">
                        CAE: {oItem.cae.number}
                      </Table.Col>
                      <Table.Col className="px-6 py-4 text-center" colSpan='5'>
                        <a
                          onClick={(e) => e.stopPropagation()}
                          target="_blank"
                          rel="noreferrer"
                          className="cursor-pointer"
                          href={oItem.cae?.link}
                        >
                          Verificar en DGI
                        </a>
                      </Table.Col>
                      <Table.Col className="px-6 py-4 text-center">
                        <div className="flex justify-center cursor-pointer" onClick={(e) => onDownload(e, oItem)}>
                          <FiDownload className="text-lg" />
                        </div>
                      </Table.Col>
                    </Table.Row>
                  }
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={10}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="4" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="4" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="4" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        show={bExport}
        onConfirm={onExportToCsv}
        title="Exportar recibos"
        onClose={() => setExport(false)}
        question="Está seguro que quiere exportar la lista de recibos"
        message="Se generará un archivo csv de los recibos que apliquen al filtro actual"
      />
      <CustomModal show={!!oDataExport} title="Archivo cargado correctamente" onClose={() => oDispatch(actionReceiptsClean())}>
        <p className="text-center">
          {
            oDataExport?.records?.length > 0
              ? "Hemos generado y cargado correctamente su archivo, descargarlo desde aquí"
              : "No se encontraron recibos para el filtro aplicado"
          }
        </p>
        <div className="mt-5 text-center md:text-right flex justify-end gap-4">
          <Button
            label="Cerrar"
            type={BUTTON_CONFIG.CLASS.DANGER}
            size={BUTTON_CONFIG.SIZE.SM}
            onClick={() => oDispatch(actionReceiptsClean())}
          />
          {
            oDataExport?.records?.length > 0 &&
            <CSVLink
              className="m-0 p-0"
              data={oDataExport?.records}
              headers={oDataExport?.headers}
              onClick={() => oDispatch(actionReceiptsClean())}
              title={`Recibos ${oDayJs().format("DD-MM-YYYY")}`}
              filename={`Recibos ${oDayJs().format("DD-MM-YYYY")}`}
            >
              <Button
                label="Descargar"
                size={BUTTON_CONFIG.SIZE.SM}
                type={BUTTON_CONFIG.CLASS.SUCCESS}
              />
            </CSVLink>
          }
        </div>
      </CustomModal>
      <ConfirmationModal
        show={!!oReSend}
        title="Re enviar recibo"
        onConfirm={onConfirmReSend}
        onClose={() => setReSend(null)}
        question={`Está seguro que quiere re envíar el recibo "${oReSend?.code}"`}
        message="Se re enviará el recibo a DGI para ser facturado"
      />
      <ConfirmationModal
        show={!!oDownload}
        title="Visualizar recibo"
        onConfirm={onConfirmDownload}
        onClose={() => setDownload(null)}
        question={`Está seguro que quiere descargar el recibo "${oDownload?.code}"`}
      />
      <ConfirmationModal
        show={!!oCancel}
        onConfirm={onCancel}
        title="Cancelar recibo"
        onClose={() => setCancel(null)}
        question={`Está seguro que desea cancelar el recibo "${oCancel?.code}"`}
      />
    </ScreenContainer>
  );
};

export default ReceiptsList;
