import oDayJs from "dayjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiCallGetAllReceipts, apiCallReceiptsCancel, apiCallReceiptsExportToCsv, apiCallReceiptsFind, apiCallReceiptsFindLines, apiCallReceiptsSave,
} from "../api/ReceiptsServices";
import {
  actionManageMessage,
  actionShowLoading,
  actionDismissLoading,
  actionMessage,
} from "redux/shared/SharedSlice";
import { ReceiptsCsvResponseMapper, ReceiptsLineMapper, ReceiptsMapper, ReceiptsUnMapper } from "./ReceiptsMapper";

import { DATES_FORMATS } from "config/constants/Dates";
import { CURRENCY_VALUES, CURRENCY_CODES } from "config/constants/Currency";
import { RECEIPT_TAX_STATUS_CODES, RECEIPT_TAX_STATUS_VALUES, RECEIPT_TYPES_CODES, RECEIPT_TYPES_VALUES } from "../constants/Receipts";
import { apiCallGetCurrencyChange } from "api/CommonApiServices";
import { CONSTANTS_MESSAGE } from "config/constants/Message";
import { apiCallDocumentDownload, apiCallSendDocumentToDGI } from "pages/documents/api/DocumentsServices";

const oInitialState = {
  aReceipts: [],
  nRecords: null,
  oReceipt: null,
  aLines: null,
  aPrices: null,

  oDataExport: null,
  sFile: null,
  bStatusSend: false,

  nPages: null,
  nPage: 1,
  oTaxStatus: RECEIPT_TAX_STATUS_VALUES.find(current => current.value === RECEIPT_TAX_STATUS_CODES.ALL),
  nCode: 0,
  nInternalCode: 0,
  sDgiNumber: 0,
  oPartner: 0,
  oCurrency: CURRENCY_VALUES.find(current => current.value === CURRENCY_CODES.NONE),
  oType: RECEIPT_TYPES_VALUES.find(current => current.value === RECEIPT_TYPES_CODES.ALL),
  dDateTo: oDayJs().toString(),
  dDateFrom: oDayJs().toString(),

  nCurrencyChange: null,
};

export const actionReceiptsGetAll = createAsyncThunk("receipts/getAllReceipts", async(args, oThunk) => {
  try {
    const { ReceiptsSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      nro: (oState?.nCode && !Number.isNaN(+oState?.nCode)) ? Number.parseInt(oState.nCode) : 0,
      nroInterno: oState?.nInternalCode ? oState.nInternalCode : 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      monid: oState.oCurrency?.value || 0,
      rectipo: oState.oType?.value || 0,
      socid: oState.oPartner?.id || 0,
      enviadoDGI: oState.oTaxStatus?.value ?? 2,
      nroDGI: oState?.sDgiNumber || "",
    };
    const oSuccess = await apiCallGetAllReceipts(oData);
    return { data: ReceiptsMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsExportToCsv = createAsyncThunk("receipts/exportToCsv", async(arg, oThunk) => {
  try {
    const { ReceiptsSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      nro: (oState?.nCode && !Number.isNaN(+oState?.nCode)) ? Number.parseInt(oState.nCode) : 0,
      nroInterno: oState?.nInternalCode ? oState.nInternalCode : 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      monid: oState.oCurrency?.value || 0,
      rectipo: oState.oType?.value || 0,
      socid: oState.oPartner?.id || 0,
      enviadoDGI: oState.oTaxStatus?.value ?? 2,
      nroDGI: oState?.sDgiNumber || "",
    };
    const oSuccess = await apiCallReceiptsExportToCsv(oData);
    return { data: ReceiptsCsvResponseMapper(oSuccess.data), records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsFind = createAsyncThunk("receipts/find", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallReceiptsFind(nId);
    return ReceiptsMapper(oSuccess.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsFindLines = createAsyncThunk("receipts/findLines", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallReceiptsFindLines(nId);
    return ReceiptsLineMapper(oSuccess?.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsGetCurrencyChange = createAsyncThunk("receipts/getCurrencyChange", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetCurrencyChange(nId);
    if (!oSuccess?.data) return null;
    return oSuccess.data.monTC;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsSave = createAsyncThunk("receipts/save", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallReceiptsSave(ReceiptsUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Recibo generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsDownload = createAsyncThunk("receipts/download", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallDocumentDownload(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Recibo descargado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oSuccess.data?.archivo;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsSendToDGI = createAsyncThunk("receipts/sandToDGI", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallSendDocumentToDGI(nId, "C");
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Recibo envíado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionReceiptsCancel = createAsyncThunk("receipts/cancel", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallReceiptsCancel(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Recibo cancelado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionReceiptsGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

const ReceiptsSlice = createSlice({
  name: "ReceiptsSlice",
  initialState: oInitialState,
  reducers: {
    actionReceiptsClean: oState => {
      oState.oReceipt = null;
      oState.aLines = null;
      oState.oDataExport = null;
      oState.sFile = null;
      oState.bStatus = false;
      oState.bStatusSend = false;
    },
    actionReceiptsChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionReceiptsChangeCode: (oState, oAction) => {
      oState.nCode = oAction.payload;
    },
    actionReceiptsChangeInternalCode: (oState, oAction) => {
      oState.nInternalCode = oAction.payload;
    },
    actionReceiptsChangeDgiNumber: (oState, oAction) => {
      oState.sDgiNumber = oAction.payload;
    },
    actionReceiptsChangePartner: (oState, oAction) => {
      oState.oPartner = oAction.payload;
    },
    actionReceiptsChangeCurrency: (oState, oAction) => {
      oState.oCurrency = oAction.payload;
    },
    actionReceiptsChangeType: (oState, oAction) => {
      oState.oType = oAction.payload;
    },
    actionReceiptsChangeTaxStatus: (oState, oAction) => {
      oState.oTaxStatus = oAction.payload;
    },
    actionReceiptsChangeDateFrom: (oState, oAction) => {
      oState.dDateFrom = oAction.payload;
    },
    actionReceiptsChangeDateTo: (oState, oAction) => {
      oState.dDateTo = oAction.payload;
    },
    actionReceiptsCleanFilters: (oState) => {
      oState.nPages = null;
      oState.nPage = 1;
      oState.oTaxStatus = RECEIPT_TAX_STATUS_VALUES.find(current => current.value === RECEIPT_TAX_STATUS_CODES.ALL);
      oState.nCode = 0;
      oState.nInternalCode = 0;
      oState.sDgiNumber = 0;
      oState.oPartner = 0;
      oState.oCurrency = CURRENCY_VALUES.find(current => current.value === CURRENCY_CODES.NONE);
      oState.oType = RECEIPT_TYPES_VALUES.find(current => current.value === RECEIPT_TYPES_CODES.ALL);
      oState.dDateTo = oDayJs().toString();
      oState.dDateFrom = oDayJs().toString();
    },
  },
  extraReducers: builder => {
    builder.addCase(actionReceiptsGetAll.fulfilled, (oState, oAction) => {
      oState.aReceipts = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionReceiptsExportToCsv.fulfilled, (oState, oAction) => {
      oState.oDataExport = oAction.payload.data;
    });
    builder.addCase(actionReceiptsFind.fulfilled, (oState, oAction) => {
      oState.oReceipt = oAction.payload;
    });
    builder.addCase(actionReceiptsFindLines.fulfilled, (oState, oAction) => {
      oState.aLines = oAction.payload;
    });
    builder.addCase(actionReceiptsSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionReceiptsDownload.fulfilled, (oState, oAction) => {
      oState.sFile = oAction.payload;
    });
    builder.addCase(actionReceiptsSendToDGI.fulfilled, (oState, oAction) => {
      oState.bStatusSend = oAction.payload;
    });
    builder.addCase(actionReceiptsGetCurrencyChange.fulfilled, (oState, oAction) => {
      oState.nCurrencyChange = oAction.payload;
    });
  },
});

export const {
  actionReceiptsClean,
  actionReceiptsChangePage,
  actionReceiptsChangeCode,
  actionReceiptsChangeInternalCode,
  actionReceiptsChangeDgiNumber,
  actionReceiptsChangePartner,
  actionReceiptsChangeCurrency,
  actionReceiptsChangeType,
  actionReceiptsChangeTaxStatus,
  actionReceiptsChangeDateFrom,
  actionReceiptsChangeDateTo,
  actionReceiptsCleanFilters,
} = ReceiptsSlice.actions;

export const selectReceiptsState = ({ ReceiptsSlice: oState }) => oState;

export default ReceiptsSlice.reducer;
