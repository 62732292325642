import SysLabels from "config/lang/SysLabels";

const Labels = {
  ...SysLabels,
  listReceipts: "Recibos",
  createReceipt: "Crear recibo",
  editReceipt: "Editar recibo",
  receiptData: "Datos del recibo",
  emptyReceipts: "Sin recibos registrados",
  dateFrom: "Fecha desde",
  dateTo: "Fecha hasta",
  lines: "Lineas",
  article: "Artículo",
  quantity: "Cantidad",
  unitPrice: "Precio unitario",
  quantityAbbreviation: "Cant",
  unitPriceAbbreviation: "P/U",
  subtotal: "Subtotal",
  total: "Total",
  emptyLines: "Sin lineas registradas",
  partner: "Socio",
  discount: "Descuento",
  tax: "Impuesto",
  taxAbbreviation: "Impto",
  unit: "Unidad",
  indicator: "Indicador de facturación",
  rounding: "Redondeo",
  totals: "Totales",
  holder: "Titular del recibo",
  documentId: "Nro documento",
  documentType: "Tipo documento",
  amount: "Importe",
  serie: "Serie",
  nro: "Número",
};

export default Labels;
