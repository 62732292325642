export const ArticlesMapper = aArticles => {
  const mapArticles = oServerArticle => ({
    id: oServerArticle.artID,
    code: oServerArticle.artCod,
    name: oServerArticle.artNom,
    idFamily: oServerArticle.famId,
    partner: {
      id: oServerArticle.socId,
      name: oServerArticle.socNom,
    },
    ivaSell: oServerArticle.ivaIDVta,
    ivaBuy: oServerArticle.ivaIDCmp,
    isActive: Boolean(oServerArticle.artActivo),
    marca: oServerArticle.artMarca,
    model: oServerArticle.artModelo,
    minStock: oServerArticle.artStkMin,
    stock: oServerArticle.artStkActual,
    type: ArticlesMapperType(oServerArticle),
    procedure: oServerArticle.artProcArmado,
    costs: {
      uyu: oServerArticle.artPrcCosUYU,
      usd: oServerArticle.artPrcCosUSD,
    },
    unit: {
      id: oServerArticle.undId,
      name: oServerArticle.undNom,
      label: oServerArticle.undNom,
    },
  });

  if (Array.isArray(aArticles)) return aArticles.map(mapArticles);
  if ((typeof aArticles).toString() === "object") return mapArticles(aArticles);
  return null;
};

export const ArticlesUnMapper = aArticles => {
  const unmapArticles = oArticle => ({
    artID: oArticle.id,
    artCod: oArticle.code,
    artNom: oArticle.name,
    tpoArtId: oArticle.idType,
    famId: oArticle.idFamily,
    socId: oArticle.partner?.id || 0,
    socNom: oArticle.partner?.name || null,
    ivaIDVta: oArticle.ivaSell,
    ivaIDCmp: oArticle.ivaBuy,
    artActivo: Number(oArticle.isActive),
    artMarca: oArticle.marca,
    artModelo: oArticle.model,
    artStkMin: Number(oArticle.minStock),
    artProcArmado: oArticle.procedure,
    formula: oArticle.formula?.map(item => ({
      ArtIdHijo: item.article?.id,
      Cantidad: item.quantity,
    })),
    artPrcCosUYU: oArticle.costs?.uyu || 0,
    artPrcCosUSD: oArticle.costs?.usd || 0,
    undId: oArticle.unit?.id || 0,
    undNom: oArticle.unit?.name || "",
  });

  if (Array.isArray(aArticles)) return aArticles.map(unmapArticles);
  if ((typeof aArticles).toString() === "object") return unmapArticles(aArticles);
  return null;
};

export const ArticlesMapperSuggest = aArticles => {
  const mapArticles = oServerArticle => ({
    id: oServerArticle.artID,
    value: oServerArticle.artID,
    label: oServerArticle.artNom,
    name: oServerArticle.artNom,
    tax: oServerArticle.ivaTasa,
    code: oServerArticle.artCod,
    ...(oServerArticle.tpoArtId && {
      type: {
        id: oServerArticle.tpoArtId,
        name: oServerArticle.tpoArtNom,
      },
    }),
    unit: {
      id: oServerArticle.undId,
      name: oServerArticle.undNom,
      label: oServerArticle.undNom,
    },
  });

  if (Array.isArray(aArticles)) return aArticles.map(mapArticles);
  if ((typeof aArticles).toString() === "object") return mapArticles(aArticles);
  return null;
};

export const ArticlesUnMapperPrice = aPrices => {
  const mapPrices = oPrice => ({
    lisid: oPrice.priceList?.id || 0,
    monid: oPrice.currency,
    precionuevo: oPrice.price,
    preciofinal: oPrice.taxInclude ? 1 : 0,
  });

  if (Array.isArray(aPrices)) return aPrices.map(mapPrices);
  if ((typeof aPrices).toString() === "object") return mapPrices(aPrices);
  return null;
};

export const ArticlesMapperPrice = aPrices => {
  const mapPrices = oPrice => ({
    article: {
      id: oPrice.artID,
      name: oPrice.artNom,
      code: oPrice.artCod,
    },
    priceList: {
      id: oPrice.lisId,
      name: oPrice.lisDsc,
    },
    currency: oPrice.monSim,
    price: oPrice.precio,
  });

  if (Array.isArray(aPrices)) return aPrices.map(mapPrices);
  if ((typeof aPrices).toString() === "object") return mapPrices(aPrices);
  return null;
};

export const ArticlesUnMapperLineInfo = oLineData => {
  const mapLineInfo = oData => ({
    artid: oData.idArticle,
    socid: oData.idPartner,
    monid: oData.currency,
    porcdto: oData.discount,
    lincnt: oData.quantity,
    linprc: oData.unitPrice,
  });

  if (Array.isArray(oLineData)) return oLineData.map(mapLineInfo);
  if ((typeof oLineData).toString() === "object") return mapLineInfo(oLineData);
  return null;
};

export const ArticlesMapperLineInfo = oLineData => {
  const mapLineInfo = oData => ({
    discount: oData.importeDto,
    unitPrice: oData.linPrc,
    subtotal: oData.subtotal,
    total: oData.total,
    tax: oData.valorIva,
    taxId: oData.ivaId,
  });

  if (Array.isArray(oLineData)) return oLineData.map(mapLineInfo);
  if ((typeof oLineData).toString() === "object") return mapLineInfo(oLineData);
  return null;
};

export const ArticlesMapperType = oTypeData => {
  const mapType = oData => ({
    id: oData.tpoArtId,
    value: oData.tpoArtId,
    name: oData.tpoArtNom,
    label: oData.tpoArtNom,
  });

  if (Array.isArray(oTypeData)) return oTypeData.map(mapType);
  if ((typeof oTypeData).toString() === "object") return mapType(oTypeData);
  return null;
};

export const ArticlesMapperFormula = aItems => {
  const map = oItem => ({
    article: {
      id: oItem.artIdHijo,
      name: oItem.artNomHijo,
      code: oItem.artCodHijo,
    },
    quantity: oItem.cantidad,
    type: {
      id: oItem.tpoArtId || oItem.tpoArtIdHijo,
      name: oItem.tpoArtNom || oItem.tpoArtNomHijo,
    },
  });

  if (Array.isArray(aItems)) return aItems.map(map);
  if ((typeof aItems).toString() === "object") return map(aItems);
  return null;
};

export const ArticlesMapperHistoricalMovement = aItems => {
  const map = oItem => ({
    partner: {
      name: oItem.socnom,
    },
    document: {
      cfe: oItem.cfeId,
      date: oItem.docfch,
      price: oItem.docLinPrc,
      type: oItem.tipoDocNom,
      quantity: oItem.docLinCnt,
    },
  });
  return map(aItems);
  // if (Array.isArray(aItems)) return aItems.map(map);
  // if ((typeof aItems).toString() === "object") return map(aItems);
  // return null;
};

