import oDayJs from "dayjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiCallGetAllResguardos,
  apiCallResguardosFind,
  apiCallResguardosFindLines,
  apiCallResguardosSave,
  apiCallResguardosExportToCsv,
  apiCallResguardoDownload,
  // apiCallResguardosUpdate,
  apiCallResguardosGetLineInfo,
  apiCallResguardoCancel,
  apiCallSendResguardoToDGI,
  apiCallResguardosGetTaxTypes,
} from "../api/ResguardosServices";
import {
  actionManageMessage,
  actionShowLoading,
  actionDismissLoading,
  actionMessage,
} from "redux/shared/SharedSlice";
import { ResguardosCsvResponseMapper, ResguardosGetLineInfoMapper, ResguardosGetLineInfoUnMapper, ResguardosLineMapper, ResguardosMapper, ResguardosTaxTypeUnMapper, ResguardosUnMapper } from "./ResguardosMapper.js";

import { DATES_FORMATS } from "config/constants/Dates";
import { CURRENCY_VALUES, CURRENCY_CODES } from "config/constants/Currency";
import { DOCUMENT_STATUS_CODES, DOCUMENT_STATUS_VALUES, DOCUMENT_TAX_STATUS_CODES, DOCUMENT_TAX_STATUS_VALUES } from "../constants/Resguardos";

import { CONSTANTS_MESSAGE } from "config/constants/Message";
import { apiCallGetCurrencyChange } from "api/CommonApiServices";

const oInitialState = {
  aResguardos: [],
  nRecords: null,
  bStatus: false,
  bStatusSend: false,
  oResguardo: null,
  aLines: null,
  aPrices: null,
  bLot: false,
  oLot: null,
  oType: null,

  oDataExport: null,
  sFile: null,
  sPrintFile: null,

  oLineInfo: null,

  nPages: null,
  nPage: 1,
  sTaxNro: "",
  oTaxStatus: DOCUMENT_TAX_STATUS_VALUES.find(current => current.value === DOCUMENT_TAX_STATUS_CODES.ALL),
  nCode: 0,
  oPartner: 0,
  oStatus: DOCUMENT_STATUS_VALUES.find(current => current.value === DOCUMENT_STATUS_CODES.NONE),
  oCurrency: CURRENCY_VALUES.find(current => current.value === CURRENCY_CODES.NONE),
  dDateTo: oDayJs().toString(),
  dDateFrom: oDayJs().toString(),

  nCurrencyChange: null,

  aRelations: [],
  bStatusRelations: false,

  aTaxTypes: [],
};

export const actionResguardosGetAll = createAsyncThunk("resguardos/getAllResguardos", async(args, oThunk) => {
  try {
    const { ResguardosSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      resid: "",
      // resnro: (oState?.nCode && !Number.isNaN(+oState?.nCode)) ? Number.parseInt(oState.nCode) : 0,
      resnro: 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      socid: oState.oPartner?.id || 0,
      enviadoDGI: oState.oTaxStatus?.value ?? 2,
      monid: oState.oCurrency?.value || 0,
    };
    const oSuccess = await apiCallGetAllResguardos(oData);
    return { data: ResguardosMapper(oSuccess.data), pages: oSuccess.pages, records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosFind = createAsyncThunk("resguardos/find", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardosFind(nId);
    return ResguardosMapper(oSuccess.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosSave = createAsyncThunk("resguardos/save", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardosSave(ResguardosUnMapper(oData));
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Resguardo generado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

// export const actionResguardosUpdate = createAsyncThunk("resguardos/update", async(oData, oThunk) => {
//   try {
//     oThunk.dispatch(actionShowLoading());
//     const oSuccess = await apiCallResguardosUpdate(oData.id, ResguardosUnMapper(oData));
//     oThunk.dispatch(
//       actionMessage({
//         message: oSuccess.message || "Resguardo actualizado correctamente",
//         type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
//       }),
//     );
//     return true;
//   } catch (oError) {
//     oThunk.dispatch(actionManageMessage(oError));
//     oThunk(oError);
//   } finally {
//     oThunk.dispatch(actionDismissLoading());
//   }
// });

export const actionResguardosFindLines = createAsyncThunk("resguardos/findLines", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardosFindLines(nId);
    return ResguardosLineMapper(oSuccess?.data || null);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosGetCurrencyChange = createAsyncThunk("resguardos/getCurrencyChange", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallGetCurrencyChange(nId);
    if (!oSuccess?.data) return null;
    return oSuccess.data.monTC;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosSendToDGI = createAsyncThunk("resguardos/sandToDGI", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallSendResguardoToDGI(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Resguardo envíado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosDownload = createAsyncThunk("resguardos/download", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardoDownload(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Resguardo descargado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    return oSuccess.data?.archivo;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

// export const actionResguardosPrint = createAsyncThunk("resguardos/print", async(nId, oThunk) => {
//   try {
//     oThunk.dispatch(actionShowLoading());
//     const oSuccess = await apiCallDocumentPrint(nId);
//     oThunk.dispatch(
//       actionMessage({
//         message: oSuccess.message || "Resguardo cargado correctamente",
//         type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
//       }),
//     );
//     return oSuccess.data?.informe;
//   } catch (oError) {
//     oThunk.dispatch(actionManageMessage(oError));
//     oThunk(oError);
//   } finally {
//     oThunk.dispatch(actionDismissLoading());
//   }
// });

export const actionResguardosExportToCsv = createAsyncThunk("resguardos/exportToCsv", async(arg, oThunk) => {
  try {
    const { ResguardosSlice: oState } = oThunk.getState();
    oThunk.dispatch(actionShowLoading());
    const oData = {
      pagina: oState.nPage,
      nro: (oState?.nCode && !Number.isNaN(+oState?.nCode)) ? Number.parseInt(oState.nCode) : 0,
      desde: oDayJs(oState.dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      hasta: oDayJs(oState.dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      monid: oState.oCurrency?.value || 0,
      docestado: oState.oStatus?.value || 0,
      socid: oState.oPartner?.id || 0,
      nroDGI: oState.sTaxNro || "",
      enviadoDGI: oState.oTaxStatus?.value ?? 2,
    };
    const oSuccess = await apiCallResguardosExportToCsv(oData);
    return { data: ResguardosCsvResponseMapper(oSuccess.data), records: oSuccess.records };
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosCancel = createAsyncThunk("resguardos/cancel", async(nId, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardoCancel(nId);
    oThunk.dispatch(
      actionMessage({
        message: oSuccess.message || "Resguardo anulado correctamente",
        type: CONSTANTS_MESSAGE.TYPE_SUCCESS,
      }),
    );
    oThunk.dispatch(actionResguardosGetAll());
    return true;
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  } finally {
    oThunk.dispatch(actionDismissLoading());
  }
});

export const actionResguardosGetLineInfo = createAsyncThunk("resguardos/getLineInfo", async(oData, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardosGetLineInfo(ResguardosGetLineInfoMapper(oData));
    oThunk.dispatch(actionDismissLoading());
    return ResguardosGetLineInfoUnMapper(oSuccess);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

export const actionResguardosGetTaxTypes = createAsyncThunk("resguardos/getTaxTypes", async(_, oThunk) => {
  try {
    oThunk.dispatch(actionShowLoading());
    const oSuccess = await apiCallResguardosGetTaxTypes();
    oThunk.dispatch(actionDismissLoading());
    return ResguardosTaxTypeUnMapper(oSuccess.data);
  } catch (oError) {
    oThunk.dispatch(actionManageMessage(oError));
    oThunk(oError);
  }
});

const ResguardosSlice = createSlice({
  name: "ResguardosSlice",
  initialState: oInitialState,
  reducers: {
    actionResguardosClean: oState => {
      oState.bStatus = false;
      oState.bStatusSend = false;
      oState.oResguardo = null;
      oState.aLines = null;
      oState.sFile = null;
      oState.sPrintFile = null;
      oState.oDataExport = null;
      oState.bLot = false;
      oState.oLot = null;
      oState.aRelations = [];
      oState.bStatusRelations = false;
      oState.aTaxTypes = [];
    },
    actionResguardosCleanLot: oState => {
      oState.bLot = false;
      oState.oLot = null;
    },
    actionResguardosChangePage: (oState, oAction) => {
      oState.nPage = oAction.payload;
    },
    actionResguardosChangeCode: (oState, oAction) => {
      oState.nCode = oAction.payload;
    },
    actionResguardosChangeTaxNro: (oState, oAction) => {
      oState.sTaxNro = oAction.payload;
    },
    actionResguardosChangePartner: (oState, oAction) => {
      oState.oPartner = oAction.payload;
    },
    actionResguardosChangeCurrency: (oState, oAction) => {
      oState.oCurrency = oAction.payload;
    },
    actionResguardosChangeType: (oState, oAction) => {
      oState.oType = oAction.payload;
    },
    actionResguardosChangeStatus: (oState, oAction) => {
      oState.oStatus = oAction.payload;
    },
    actionResguardosChangeTaxStatus: (oState, oAction) => {
      oState.oTaxStatus = oAction.payload;
    },
    actionResguardosChangeDateFrom: (oState, oAction) => {
      oState.dDateFrom = oAction.payload;
    },
    actionResguardosChangeDateTo: (oState, oAction) => {
      oState.dDateTo = oAction.payload;
    },
    actionResguardosCleanRelations: oState => {
      oState.bStatusRelations = false;
    },
    actionResguardosCleanStatus: oState => {
      oState.bStatus = false;
    },
    actionResguardosCleanPrintFile: oState => {
      oState.sPrintFile = null;
    },
    actionResguardosCleanFilters: oState => {
      oState.sPrintFile = null;
      oState.nPages = null;
      oState.nPage = 1;
      oState.sTaxNro = "";
      oState.oTaxStatus = DOCUMENT_TAX_STATUS_VALUES.find(current => current.value === DOCUMENT_TAX_STATUS_CODES.ALL);
      oState.nCode = 0;
      oState.oPartner = 0;
      oState.oStatus = DOCUMENT_STATUS_VALUES.find(current => current.value === DOCUMENT_STATUS_CODES.NONE);
      oState.oCurrency = CURRENCY_VALUES.find(current => current.value === CURRENCY_CODES.NONE);
      oState.dDateTo = oDayJs().toString();
      oState.dDateFrom = oDayJs().toString();
    },
    actionResguardosCleanLineInfo: oState => {
      oState.oLineInfo = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(actionResguardosGetAll.fulfilled, (oState, oAction) => {
      oState.aResguardos = oAction.payload.data;
      oState.nPages = oAction.payload.pages;
      oState.nRecords = oAction.payload.records;
    });
    builder.addCase(actionResguardosExportToCsv.fulfilled, (oState, oAction) => {
      oState.oDataExport = oAction.payload.data;
    });
    builder.addCase(actionResguardosFind.fulfilled, (oState, oAction) => {
      oState.oResguardo = oAction.payload;
    });
    builder.addCase(actionResguardosFindLines.fulfilled, (oState, oAction) => {
      oState.aLines = oAction.payload;
    });
    builder.addCase(actionResguardosGetCurrencyChange.fulfilled, (oState, oAction) => {
      oState.nCurrencyChange = oAction.payload;
    });
    builder.addCase(actionResguardosSave.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionResguardosSendToDGI.fulfilled, (oState, oAction) => {
      oState.bStatusSend = oAction.payload;
    });
    builder.addCase(actionResguardosDownload.fulfilled, (oState, oAction) => {
      oState.sFile = oAction.payload;
    });
    // builder.addCase(actionResguardosUpdate.fulfilled, (oState, oAction) => {
    //   oState.bStatus = oAction.payload;
    // });
    builder.addCase(actionResguardosGetLineInfo.fulfilled, (oState, oAction) => {
      oState.oLineInfo = oAction.payload;
    });
    builder.addCase(actionResguardosCancel.fulfilled, (oState, oAction) => {
      oState.bStatus = oAction.payload;
    });
    builder.addCase(actionResguardosGetTaxTypes.fulfilled, (oState, oAction) => {
      oState.aTaxTypes = oAction.payload;
    });
  },
});

export const {
  actionResguardosClean,
  actionResguardosCleanLot,
  actionResguardosChangePage,
  actionResguardosChangeCode,
  actionResguardosChangeTaxNro,
  actionResguardosChangePartner,
  actionResguardosChangeCurrency,
  actionResguardosChangeType,
  actionResguardosChangeStatus,
  actionResguardosChangeTaxStatus,
  actionResguardosChangeDateFrom,
  actionResguardosChangeDateTo,
  actionResguardosCleanRelations,
  actionResguardosCleanStatus,
  actionResguardosCleanPrintFile,
  actionResguardosCleanFilters,
  actionResguardosCleanLineInfo,
} = ResguardosSlice.actions;

export const selectResguardosState = ({ ResguardosSlice: oState }) => oState;

export default ResguardosSlice.reducer;
