export const PartnersMapper = aPartners => {
  const mapPartners = oServerPartner => ({
    id: oServerPartner.socID,
    name: oServerPartner.socNom,
    code: oServerPartner.socCod,
    email: oServerPartner.socEmail,
    document: oServerPartner.socCI,
    observations: oServerPartner.socObs,
    gln: oServerPartner.socGLN,
    businessName: oServerPartner.socRazSoc,
    rut: oServerPartner.socRut,
    address: oServerPartner.socDir,
    phone1: oServerPartner.socTel1,
    phone2: oServerPartner.socTel2,
    type: oServerPartner.socTpoEmp,
    url: oServerPartner.socWeb,
    idPriceList: oServerPartner.lisId,
    idLocation: oServerPartner.locId,
    isActive: oServerPartner.socActivo ? true : false,
    isClient: oServerPartner.socEsCli ? true : false,
    isProvider: oServerPartner.socEsPrv ? true : false,
    isTaxed: oServerPartner.socGravado ? true : false,
    dueBalance: oServerPartner.socSdoCliPesIni,
    dueBalanceUsd: oServerPartner.socSdoCliUsdIni,
    creditBalance: oServerPartner.socSdoPrvPesIni,
    creditBalanceUsd: oServerPartner.socSdoPrvUsdIni,
    seller: {
      id: oServerPartner.venId,
      name: oServerPartner.venNom,
      value: oServerPartner.venId,
      label: oServerPartner.venNom,
    },
    createdAt: oServerPartner.socFchIng,
    idDocumentType: oServerPartner.tipodocid,
    nameDocumentType: oServerPartner.tipoDocNom,
    zip: oServerPartner.socCP,
    discount: oServerPartner.socDtoGlobal,
    idGroup: oServerPartner.grsId,

    father: oServerPartner.padId ? {
      id: oServerPartner.padId,
      name: oServerPartner.padNom,
    } : null,

    // Field for combo
    label: oServerPartner.socNom,
  });

  if (Array.isArray(aPartners)) return aPartners.map(mapPartners);
  if ((typeof aPartners).toString() === "object") return mapPartners(aPartners);
  return null;
};

export const PartnersUnMapper = aPartners => {
  const unMapPartners = oPartner => ({
    socID: oPartner.id,
    socNom: oPartner.name,
    socCod: oPartner.code,
    socEmail: oPartner.email,
    socCI: oPartner.document,
    socObs: oPartner.observations,
    socGLN: oPartner.gln,
    socRazSoc: oPartner.businessName,
    socRut: oPartner.rut,
    socDir: oPartner.address,
    socTel1: oPartner.phone1,
    socTel2: oPartner.phone2,
    socTpoEmp: oPartner.type,
    socWeb: oPartner.url,
    lisId: oPartner.idPriceList,
    locId: oPartner.idLocation,
    socActivo: oPartner.isActive ? 1 : 0,
    socEsCli: oPartner.isClient ? 1 : 0,
    socEsPrv: oPartner.isProvider ? 1 : 0,
    socGravado: oPartner.isTaxed ? 1 : 0,
    socSdoCliPesIni: oPartner.dueBalance,
    socSdoCliUsdIni: oPartner.dueBalanceUsd,
    socSdoPrvPesIni: oPartner.creditBalance,
    socSdoPrvUsdIni: oPartner.creditBalanceUsd,
    socFchIng: oPartner.createdAt,
    Tipodocid: oPartner.idDocumentType ?? 0,
    soccp: oPartner.zip,
    padId: oPartner.idFather ?? 0,
    socDtoGlobal: oPartner.discount ?? 0,
    grsId: oPartner.idGroup,
    venId: oPartner.idSeller ?? 0,
  });

  if (Array.isArray(aPartners)) return aPartners.map(unMapPartners);
  if ((typeof aPartners).toString() === "object") return unMapPartners(aPartners);
  return null;
};

export const PartnersMapperSuggest = aPartners => {
  const mapPartners = oServerPartner => ({
    id: oServerPartner.socID,
    value: oServerPartner.socID,
    label: oServerPartner.socNom,
    idPriceList: oServerPartner.lisId,
    rut: oServerPartner.socRut,
    email: oServerPartner.socEmail,
    phone: oServerPartner.socTel1,
    document: oServerPartner.socCI,
    address: oServerPartner.socDir,
    businessName: oServerPartner.socRazSoc,
    discount: oServerPartner.socDtoGlobal,
    seller: {
      id: oServerPartner.venId,
      name: oServerPartner.venNom,
      value: oServerPartner.venId,
      label: oServerPartner.venNom,
    },
  });

  if (Array.isArray(aPartners)) return aPartners.map(mapPartners);
  if ((typeof aPartners).toString() === "object") return mapPartners(aPartners);
  return null;
};

export const PartnersMapperCurrentAccount = oData => {
  return {
    ...(oData.to && { hasta: oData.to }),
    ...(oData.from && { desde: oData.from }),
    socid: oData.idPartner,
    monid: oData.idCurrency,
    incsuc: oData.includeBranches ? 1 : 0,
    devolverinforme: oData.getReport,
  };
};

export const PartnersMapperStatementAccount = aData => {
  const mapStatementAccount = oItem => ({
    id: oItem.id,
    date: oItem.fecha,
    type: oItem.tipo,
    identifier: oItem.identificacion,
    amount: oItem.importe,
    partial: oItem.parcial,
  });

  if (Array.isArray(aData)) return aData.map(mapStatementAccount);
  if ((typeof aData).toString() === "object") return [mapStatementAccount(aData)];
  return null;
};

export const PartnersMapperStatementAccountReport = oData => {
  return oData?.informe ?? null;
};
