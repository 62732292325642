import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FaPlus, FaTrashAlt } from "react-icons/fa";

import {
  ScreenContainer,
  Text,
  Input,
  Button,
  Table,
  ConfirmationModal,
  Checkbox,
} from "components";

import {
  actionPartnersChangeFilter,
  actionPartnersChangeFilterClients,
  actionPartnersChangeFilterProviders,
  actionPartnersChangePage,
  actionPartnersClean,
  actionPartnersDelete,
  actionPartnersGetAll,
  selectAllPartners,
  selectPagesPartners,
  selectPartnerFilter,
  selectPartnerFilterClients,
  selectPartnerFilterProviders,
  selectPartnerPage,
  selectRecordsPartners,
  selectStatusPartners,
} from "pages/partners/redux";

import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import PartnersLang from "./lang";

const PartnersList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();

  const aPartners = useSelector(selectAllPartners);
  const nPages = useSelector(selectPagesPartners);
  const nRecords = useSelector(selectRecordsPartners);
  const bStatus = useSelector(selectStatusPartners);

  const nPage = useSelector(selectPartnerPage);
  const sFilter = useSelector(selectPartnerFilter);
  const bClients = useSelector(selectPartnerFilterClients);
  const bProviders = useSelector(selectPartnerFilterProviders);

  const [bDelete, setDelete] = useState(false);
  const [oPartnerDelete, setPartnerDelete] = useState(null);

  useEffect(() => {
    oDispatch(actionPartnersGetAll());
  }, []);

  useEffect(() => {
    if (bStatus) {
      oDispatch(actionPartnersGetAll());
      oDispatch(actionPartnersClean());
    }
  }, [bStatus]);

  const onChangeTimeout = (oState, oData) => {
    if (!oData) { return oState; }
    if (oState) { clearTimeout(oState); }
    return oData;
  };

  const [, setTimer] = useReducer(onChangeTimeout, null);

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionPartnersChangePage(nSelectedPage));
    oDispatch(actionPartnersGetAll());
  };

  const onChangeFilter = (sNewFilter) => {
    oDispatch(actionPartnersChangeFilter(sNewFilter));
    setTimer(setTimeout(() => {
      oDispatch(actionPartnersChangePage(1));
      oDispatch(actionPartnersGetAll());
    }, 800));
  };

  const onChangeFilterBoolean = (identifier) => {
    if (identifier === "clients") oDispatch(actionPartnersChangeFilterClients(!bClients));
    if (identifier === "providers") oDispatch(actionPartnersChangeFilterProviders(!bProviders));
    oDispatch(actionPartnersChangePage(1));
    oDispatch(actionPartnersGetAll());
  };

  const onNavigate = () => {
    oNavigator("/partners/new");
  };

  const onView = (oPartner) => {
    oNavigator(`/partners/${oPartner.id}`);
  };

  const onDelete = oPartner => {
    setPartnerDelete(oPartner);
    setDelete(true);
  };

  const onConfirmDelete = () => {
    setDelete(false);
    setPartnerDelete(null);
    oDispatch(actionPartnersDelete(oPartnerDelete.id));
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          {PartnersLang.Labels.listPartners}
        </Text>
        <Button
          onClick={onNavigate}
          label={PartnersLang.Buttons.new}
          containerClassName="ml-auto"
          className="flex align-middle"
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
        />
      </section>
      <section className="grid grid-cols-4 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-4">
        <div className="order-1 relative col-span-4 md:col-span-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={sFilter}
            floatLabel={false}
            onChange={onChangeFilter}
            placeholder={PartnersLang.Buttons.search}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="order-3 md:order-2 col-span-4 md:col-span-3 flex items-center justify-end gap-4">
          <Checkbox
            label="Clientes"
            checked={bClients}
            onChange={() => onChangeFilterBoolean("clients")}
          />
          <Checkbox
            label="Proveedores"
            checked={bProviders}
            onChange={() => onChangeFilterBoolean("providers")}
          />
        </div>
        <Text size="text-xs" className="order-2 md:order-3 col-span-4 px-1 text-center md:text-left">Se buscará por nombre, razón social o RUT</Text>
      </section>
      <section className="py-5">
        <Table data-testid={"PartnersTable"}>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3">
                {PartnersLang.Fields.name}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 hidden md:table-cell">
                {PartnersLang.Fields.businessName}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {PartnersLang.Fields.phone}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {PartnersLang.Fields.rut}
              </Table.Col>
              <Table.Col scope="col" className="hidden md:table-cell" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aPartners?.length > 0 ? (
              aPartners.map((oPartner, nIndex) => (
                <Table.Row key={oPartner.id} onClick={() => onView(oPartner)}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4">
                    {oPartner.name}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 hidden md:table-cell">
                    {oPartner.businessName}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oPartner.phone1 || oPartner.phone2}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oPartner.rut}
                  </Table.Col>
                  <Table.Col className="text-center px-2 hidden md:table-cell">
                    <div className="flex justify-center">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(oPartner);
                        }}
                        size={BUTTON_CONFIG.SIZE.SM}
                        type={BUTTON_CONFIG.CLASS.DANGER}
                        icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
                      />
                    </div>
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={5}>
                  <em>
                    {sFilter.length > 0
                      ? "No se encontraron resultados"
                      : "Sin socios registrados"}
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="3" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        show={bDelete}
        onClose={() => setDelete(false)}
        onConfirm={onConfirmDelete}
        title="Eliminar socio"
        question={`Está seguro que desea eliminar el socio "${oPartnerDelete?.name}"`}
        message="Esta acción no se puede revertir"
      />
    </ScreenContainer>
  );
};

export default PartnersList;
