import oAxiosInstance from "config/Axios";

export const apiCallGetAllDocuments = oData => {
  return oAxiosInstance.post("documentos/consultar", oData);
};

export const apiCallDocumentsExportToCsv = oData => {
  return oAxiosInstance.post("documentos/exportarcsv", oData);
};

export const apiCallDocumentsFind = nId => {
  return oAxiosInstance.get(`documentos/consultarCabezal/${nId}`);
};

export const apiCallDocumentsFindLines = nId => {
  return oAxiosInstance.get(`documentos/consultarLineas/${nId}`);
};

export const apiCallDocumentsSave = oData => {
  return oAxiosInstance.post("documentos", oData);
};

export const apiCallDocumentsUpdate = (nId, oData) => {
  return oAxiosInstance.put(`documentos/${nId}`, oData);
};

export const apiCallDocumentsDelete = nId => {
  return oAxiosInstance.delete(`documentos/${nId}`);
};

export const apiCallSendDocumentToDGI = (nId, sType = "D") => {
  return oAxiosInstance.get(`efactura/reenviarcfe/${nId}/${sType}`);
};

export const apiCallDocumentDownload = nId => {
  return oAxiosInstance.get(`efactura/pdfcfe/${nId}`);
};

export const apiCallDocumentPrint = nId => {
  return oAxiosInstance.get(`documentos/imprimir/${nId}`);
};

export const apiCallDocumentCancel = nId => {
  return oAxiosInstance.get(`documentos/anular/${nId}`);
};

export const apiCallDocumentGetRelations = nId => {
  return oAxiosInstance.get(`documentos/traerdocsrelacionados/${nId}`);
};

export const apiCallDocumentGetDocumentsRelations = oData => {
  return oAxiosInstance.post("documentos/buscardocsportipoynro", oData);
};

export const apiCallDocumentSaveRelations = oData => {
  return oAxiosInstance.post("documentos/agregarelacion", oData);
};

export const apiCallDocumentDeleteRelations = oData => {
  return oAxiosInstance.post("documentos/quitarelacion", oData);
};

export const apiCallDocumentsSuggest = oData => {
  return oAxiosInstance.post("documentos/buscardocsportipoynro", oData);
};

export const apiCallDocumentsImportFromFile = oData => {
  const oFormData = new FormData();
  oFormData.append("archivo", oData[0]);
  return oAxiosInstance.post("documentos/subirarchivo", oFormData);
};
