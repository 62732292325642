import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiDownload, FiSearch, FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BsFilePdf } from "react-icons/bs";
import { BiExport } from "react-icons/bi";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import oDayJs from "dayjs";

import {
  ScreenContainer,
  Text,
  Table,
  DateInput,
  PartnerSuggestions,
  Dropdown,
  ConfirmationModal,
  Button,
} from "components";

import { BUTTON_CONFIG } from "config/constants/Button";
import ResguardosLang from "./lang";
import {
  actionResguardosChangeDateFrom,
  actionResguardosChangeDateTo,
  actionResguardosChangePage,
  actionResguardosClean,
  actionResguardosGetAll,
  actionResguardosChangePartner,
  selectResguardosState,
  actionResguardosSendToDGI,
  actionResguardosExportToCsv,
  actionResguardosDownload,
  actionResguardosCancel,
  actionResguardosCleanFilters,
} from "./redux";

import CustomModal from "components/CustomModal";
import { CastDate } from "utils/SharedUtils";
import { actionDocumentTypesGetAll, selectDocumentTypesState } from "pages/documentTypes";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import { FaPlus } from "react-icons/fa6";

const ResguardosList = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();
  const [oReSend, setReSend] = useState(null);
  const [bExport, setExport] = useState(false);
  const [sOpenRow, setOpenRow] = useState(null);
  const [oDownload, setDownload] = useState(null);

  const [oCancel, setCancel] = useState(null);

  // const aNewButtons = [
  //   {
  //     onClick: () => onNavigate(),
  //     label: "Nuevo",
  //   },
  //   {
  //     onClick: () => onNavigate({ type: 1 }),
  //     label: "Venta Contado",
  //   },
  //   {
  //     onClick: () => onNavigate({ type: 2 }),
  //     label: "Venta Crédito",
  //   },
  // ];

  const aOptionButtons = [
    {
      onClick: () => setExport(true),
      label: "Exportar",
    },
    {
      onClick: () => onNavigate(),
      label: "Nuevo",
    },
    // {
    //   onClick: () => onNavigate({ type: 1 }),
    //   label: "Venta Contado",
    // },
    // {
    //   onClick: () => onNavigate({ type: 2 }),
    //   label: "Venta Crédito",
    // },
  ];

  const {
    aResguardos,
    nPage,
    // nCode,
    // sTaxNro,
    nPages,
    nRecords,
    bStatus,
    bStatusSend,
    dDateTo,
    dDateFrom,
    // oStatus,
    // oTaxStatus,
    // oCurrency,
    oDataExport,
    sFile,
    // oType,
  } = useSelector(selectResguardosState);
  const { aDocumentTypes } = useSelector(selectDocumentTypesState);

  useEffect(() => {
    oDispatch(actionResguardosGetAll());
    if (!aDocumentTypes || aDocumentTypes.length === 0) oDispatch(actionDocumentTypesGetAll());
    return () => {
      oDispatch(actionResguardosCleanFilters());
    };
  }, []);

  useEffect(() => {
    if (sFile) {
      printJS({
        base64: true,
        printable: sFile,
        modalMessage: "Cargando...",
        documentTitle: oDownload.id,
        onPrintDialogClose: () => {
          setDownload(null);
          oDispatch(actionResguardosClean());
        },
      });
      setDownload(null);
    }
  }, [sFile]);

  useEffect(() => {
    if (bStatus || bStatusSend) {
      oDispatch(actionResguardosGetAll());
      oDispatch(actionResguardosClean());
    }
  }, [bStatus, bStatusSend]);

  // const onChangeTimeout = (oState, oData) => {
  //   if (!oData) { return oState; }
  //   if (oState) { clearTimeout(oState); }
  //   return oData;
  // };

  // const [, setTimer] = useReducer(onChangeTimeout, null);

  const onChangePage = (nSelectedPage) => {
    oDispatch(actionResguardosChangePage(nSelectedPage));
    oDispatch(actionResguardosGetAll());
  };

  // const onChangeCode = (sNewCode) => {
  //   oDispatch(actionResguardosChangeCode(sNewCode.trim()));
  //   setTimer(setTimeout(() => {
  //     oDispatch(actionResguardosChangePage(1));
  //     oDispatch(actionResguardosGetAll());
  //   }, 800));
  // };

  // const onChangeTaxNro = (sNewCode) => {
  //   oDispatch(actionResguardosChangeTaxNro(sNewCode.trim()));
  //   setTimer(setTimeout(() => {
  //     oDispatch(actionResguardosChangePage(1));
  //     oDispatch(actionResguardosGetAll());
  //   }, 800));
  // };

  const onChangePartner = (oPartner) => {
    oDispatch(actionResguardosChangePartner(oPartner));
    oDispatch(actionResguardosChangePage(1));
    oDispatch(actionResguardosGetAll());
  };

  const onChangeDateFrom = (dDate) => {
    oDispatch(actionResguardosChangeDateFrom(oDayJs(dDate).toString()));
    oDispatch(actionResguardosChangePage(1));
    oDispatch(actionResguardosGetAll());
  };

  const onChangeDateTo = (dDate) => {
    oDispatch(actionResguardosChangeDateTo(oDayJs(dDate).toString()));
    oDispatch(actionResguardosChangePage(1));
    oDispatch(actionResguardosGetAll());
  };

  // const onChangeCurrency = (oCurrency) => {
  //   oDispatch(actionResguardosChangeCurrency(oCurrency));
  //   oDispatch(actionResguardosChangePage(1));
  //   oDispatch(actionResguardosGetAll());
  // };

  // const onChangeType = (oType) => {
  //   oDispatch(actionResguardosChangeType(oType));
  //   oDispatch(actionResguardosChangePage(1));
  //   oDispatch(actionResguardosGetAll());
  // };

  // const onChangeStatus = (oStatus) => {
  //   oDispatch(actionResguardosChangeStatus(oStatus));
  //   oDispatch(actionResguardosChangePage(1));
  //   oDispatch(actionResguardosGetAll());
  // };

  // const onChangeTaxStatus = (oStatus) => {
  //   oDispatch(actionResguardosChangeTaxStatus(oStatus));
  //   oDispatch(actionResguardosChangePage(1));
  //   oDispatch(actionResguardosGetAll());
  // };

  const onDownload = (event, oItem) => {
    event.stopPropagation();
    if (oItem.cae.status) return setDownload(oItem);
  };

  const onConfirmReSend = () => {
    oDispatch(actionResguardosSendToDGI(oReSend.id));
    setReSend(null);
  };

  const onConfirmDownload = () => {
    oDispatch(actionResguardosDownload(oDownload.id));
  };

  const onExportToCsv = () => {
    setExport(false);
    oDispatch(actionResguardosExportToCsv());
  };

  const onReSendOrOpen = (event, oItem) => {
    event.stopPropagation();
    if (oItem.cae?.status) {
      if (sOpenRow === oItem.id)
        return setOpenRow(null);
      return setOpenRow(oItem.id);
    }
    setReSend(oItem);
  };

  const onView = (oItem) => oNavigator(`/resguardos/${oItem.id}`);
  const onNavigate = (oOptions) => oNavigator("/resguardos/new", { state: oOptions });

  const onCancel = (e, oItem) => {
    if (e) e.stopPropagation();
    if (!oCancel) return setCancel(oItem);
    oDispatch(actionResguardosCancel(oCancel.id));
    setCancel(null);
  };

  return (
    <ScreenContainer>
      <section className="grid grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Resguardos
        </Text>
        <div className="gap-2 hidden md:flex">
          <Button
            label="Exportar"
            containerClassName="ml-auto"
            onClick={() => setExport(true)}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<BiExport className="text-white ml-2 text-lg" />}
          />
          <Button
            label="Nuevo"
            onClick={onNavigate}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaPlus size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
        <div className="gap-2 flex md:hidden">
          <Dropdown
            label="Opciones"
            items={aOptionButtons}
            className="flex align-middle"
            type={BUTTON_CONFIG.CLASS.SUCCESS}
          />
        </div>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-3 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        {/* <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <Input
            withoutClass
            value={nCode}
            floatLabel={false}
            onChange={onChangeCode}
            placeholder={ResguardosLang.Fields.number}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div> */}
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Desde</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={onChangeDateFrom}
            placeholder={ResguardosLang.Labels.dateFrom}
            containerClassName="rounded-lg overflow-hidden ml-12"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Hasta</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={onChangeDateTo}
            placeholder={ResguardosLang.Labels.dateTo}
            containerClassName="rounded-lg overflow-hidden ml-12"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        {/* <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Estado</span>
          </div>
          <ComboBox
            selected={oStatus}
            size="sm"
            onChange={onChangeStatus}
            emptyLabel={ResguardosLang.Fields.status}
            options={DOCUMENT_STATUS_VALUES}
          />
        </div> */}
        {/* <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Estado</span>
          </div>
          <ComboBox
            size="sm"
            selected={oTaxStatus}
            onChange={onChangeTaxStatus}
            options={DOCUMENT_TAX_STATUS_VALUES}
            emptyLabel={ResguardosLang.Fields.status}
          />
        </div> */}
        {/* <div className="relative col-span-2 md:col-span-1 gap-5">
          <ComboBox
            selected={oCurrency}
            onChange={onChangeCurrency}
            emptyLabel={ResguardosLang.Fields.currency}
            options={CURRENCY_VALUES}
          />
        </div> */}
        {/* <div className="relative col-span-2 md:col-span-1 gap-5">
          <ComboBox
            size="sm"
            selected={oType}
            onChange={onChangeType}
            options={aDocumentTypes}
            emptyLabel={ResguardosLang.Fields.type}
          />
        </div> */}
        <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-full">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400 z-10" />
          </div>
          <PartnerSuggestions
            onSelect={onChangePartner}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        {/* <div className="relative col-span-2 md:col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none h-full">
            <FiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400 z-10" />
          </div>
          <Input
            withoutClass
            value={sTaxNro}
            floatLabel={false}
            onChange={onChangeTaxNro}
            placeholder={ResguardosLang.Fields.taxOrInternalNumber}
            containerClassName="rounded-lg overflow-hidden"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div> */}
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center">
                Nro de resguardo
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Fecha
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3">
                Socio de negocio
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-center hidden md:table-cell">
                Ref de doc
              </Table.Col>
              <Table.Col scope="col" className="px-3 md:px-6 py-3 text-right hidden md:table-cell">
                Estado DGI
              </Table.Col>
              <Table.Col scope="col" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aResguardos?.length > 0 ? (
              aResguardos.map((oItem, index) => (
                <React.Fragment key={`${oItem.id}_${index}`}>
                  <Table.Row onClick={() => onView(oItem)}>
                    <Table.Col
                      className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                    >
                      {oItem.code}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                      {CastDate(oItem.date)}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4">
                      {oItem.partner?.name || "-"}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 text-center hidden md:table-cell">
                      {oItem?.observations}
                    </Table.Col>
                    <Table.Col className="px-3 md:px-6 py-4 flex items-center justify-center gap-3">
                      <div className="flex justify-center cursor-pointer" onClick={e => onReSendOrOpen(e, oItem)}>
                        {oItem.cae?.status
                          ? <BsFilePdf className="text-lg text-green-400 dark:text-green-400" />
                          : <FiSend className="text-lg text-red-400 dark:text-red-400" />
                        }
                      </div>
                    </Table.Col>
                  </Table.Row>
                  {
                    sOpenRow === oItem.id &&
                    <Table.Row>
                      <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                        CAE: {oItem.cae.number}
                      </Table.Col>
                      <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                        <a
                          onClick={(e) => e.stopPropagation()}
                          target="_blank"
                          rel="noreferrer"
                          href={oItem.cae?.link}
                        >
                          Verificar en DGI
                        </a>
                      </Table.Col>
                      <Table.Col className="px-6 py-4 text-center" colSpan='2'>
                        <div className="flex justify-center cursor-pointer" onClick={(e) => onDownload(e, oItem)}>
                          <FiDownload className="text-lg" />
                        </div>
                      </Table.Col>
                    </Table.Row>
                  }
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={7}>
                  <em>
                    No se encontraron resultados
                  </em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 pb-3 pt-5 text-center md:hidden">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
            <Table.Row isHeaderOrFooter>
              <Table.Col colSpan="3" className="px-6 py-3 text-center md:text-left">
                <Text>Total: {nRecords || "0"}</Text>
              </Table.Col>
              <Table.Col colSpan="4" className="px-6 py-3 text-right hidden md:table-cell">
                <Table.Pagination total={nPages} page={nPage} onChange={onChangePage} records={nRecords} />
              </Table.Col>
            </Table.Row>
          </Table.Footer>
        </Table>
      </section>
      <ConfirmationModal
        show={!!oReSend}
        title="Re enviar resguardo"
        onConfirm={onConfirmReSend}
        onClose={() => setReSend(null)}
        question={`Está seguro que quiere re envíar el resguardo "${oReSend?.code}"`}
        message="Se re enviará el resguardo a DGI para ser facturado"
      />
      <ConfirmationModal
        show={!!oDownload}
        title="Visualizar resguardo"
        onConfirm={onConfirmDownload}
        onClose={() => setDownload(null)}
        question={`Está seguro que quiere descargar el resguardo "${oDownload?.code}"`}
      />
      <ConfirmationModal
        show={bExport}
        onConfirm={onExportToCsv}
        title="Exportar resguardos"
        onClose={() => setExport(false)}
        question="Está seguro que quiere exportar la lista de resguardos"
        message="Se generará un archivo csv de los resguardos que apliquen al filtro actual"
      />
      <CustomModal show={!!oDataExport} title="Archivo cargado correctamente" onClose={() => oDispatch(actionResguardosClean())}>
        <p className="text-center">
          {
            oDataExport?.records?.length > 0
              ? "Hemos generado y cargado correctamente su archivo, descargarlo desde aquí"
              : "No se encontraron resguardos para el filtro aplicado"
          }
        </p>
        <div className="mt-5 text-center md:text-right flex justify-end gap-4">
          <Button
            label="Cerrar"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.DANGER}
            onClick={() => oDispatch(actionResguardosClean())}
          />
          {
            oDataExport?.records?.length > 0 &&
            <CSVLink
              className="m-0 p-0"
              data={oDataExport?.records}
              headers={oDataExport?.headers}
              onClick={() => oDispatch(actionResguardosClean())}
              title={`Resguardos ${oDayJs().format("DD-MM-YYYY")}`}
              filename={`Resguardos ${oDayJs().format("DD-MM-YYYY")}`}
            >
              <Button
                label="Descargar"
                size={BUTTON_CONFIG.SIZE.SM}
                type={BUTTON_CONFIG.CLASS.SUCCESS}
              />
            </CSVLink>
          }
        </div>
      </CustomModal>
      <ConfirmationModal
        show={!!oCancel}
        onConfirm={onCancel}
        title="Cancelar resguardo"
        onClose={() => setCancel(null)}
        question={`Está seguro que desea cancelar el resguardo "${oCancel?.code}"`}
      />
    </ScreenContainer>
  );
};

export default ResguardosList;
