import { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { FaFile, FaInbox } from "react-icons/fa6";

import { CONSTANTS_COLORS } from "config/constants/Colors";

export default function DropFile({
  file,
  upload,
  accept,
  children,
  showError,
  identifier,
  message = "Arrastre aquí el archivo",
  activeMessage = "Suelte el archivo",
}) {

  const [isDragging, setIsDragging] = useState(false);

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    if (!event.dataTransfer) return;
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      if (newFiles.length > 0) upload(newFiles, identifier);
    }
  };

  return (
    <div
      onDragOver={(event) => {
        event.preventDefault();
        setIsDragging(true);
      }}
      onDrop={handleDrop}
      className={`relative border ${showError ? "border-red-500 border-solid rounded-lg" : "border-gray-800 border-dashed rounded-sm"
      } h-[160px] bg-[#FAFAFA] dark:bg-gray-600 flex items-center justify-center flex-col cursor-pointer`}
      onChange={handleDrop}
    >
      <input
        type="file"
        name="file"
        accept={accept}
        className="opacity-0 absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
        onChange={e => {
          event.preventDefault();
          upload(e.target?.files ?? [], identifier);
        }}
      />
      {file
        ? <BsCheck size={88} color={CONSTANTS_COLORS.COLORS.SUCCESS_COLOR} />
        : children ?
          children
          : <>
            {
              isDragging
                ? <FaInbox size={40} color={CONSTANTS_COLORS.COLORS.INHERIT} className="mb-3" />
                : <FaFile size={40} color={CONSTANTS_COLORS.COLORS.INHERIT} className="mb-3" />
            }
            {isDragging ? activeMessage : message}
          </>
      }
    </div>
  );
}
