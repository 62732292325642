export const RECEIPT_TAX_STATUS_CODES = {
  NO_SENT: 0,
  SENT: 1,
  ALL: 2,
};

export const RECEIPT_TAX_STATUS_VALUES = [
  { label: "Todos", value: RECEIPT_TAX_STATUS_CODES.ALL },
  { label: "Enviados", value: RECEIPT_TAX_STATUS_CODES.SENT },
  { label: "Pendientes de envío", value: RECEIPT_TAX_STATUS_CODES.NO_SENT },
];

export const RECEIPT_TYPES_CODES = {
  ALL: 0,
  CHARGE: 1,
  PAY: 2,
};

export const RECEIPT_TYPES_VALUES = [
  { label: "Todos", value: RECEIPT_TYPES_CODES.ALL },
  { label: "Cobros", value: RECEIPT_TYPES_CODES.CHARGE },
  { label: "Pagos", value: RECEIPT_TYPES_CODES.PAY },
];

export const RECEIPT_LINE_TYPES_CODES = {
  ACCOUNT: 1,
  DETAILED: 2,
  DOCUMENTS_DISCOUNT: 3,
};

export const RECEIPT_LINE_TYPES_VALUES = [
  { label: "Pago a cuenta", value: RECEIPT_LINE_TYPES_CODES.ACCOUNT },
  { label: "Descuento a documentos", value: RECEIPT_LINE_TYPES_CODES.DOCUMENTS_DISCOUNT },
];
