import React from "react";
import Text from "./Text";
import { BUTTON_CONFIG } from "config/constants/Button";

const Button = ({
  onClick = null,
  full: bIsFull = false,
  label: sLabel = null,
  size: nSize = BUTTON_CONFIG.SIZE.MD,
  className: sCustomClassName = "",
  type: sType = BUTTON_CONFIG.CLASS.PRIMARY,
  containerClassName: sContainerClassName = "",
  icon: oIcon = null,
  active: isActive = false,
  iconPosition: sIconPosition = BUTTON_CONFIG.POSITION.RIGHT,
  disabled: bDisabled = false,
}) => {
  const aClassName = [
    "cursor-pointer",
    "inline-block",
    "rounded-md",
    "font-medium",
    "border",
    "border-solid",
    "text-center",
    "transition-colors",
    "duration-200",
  ];
  let sLabelColor = null;

  if (oIcon) aClassName.push("flex", "items-center");

  switch (sType) {
    case BUTTON_CONFIG.CLASS.SUCCESS:
      aClassName.push(
        "bg-green-600 dark:bg-green-800",
        "border-green-600 dark:border-green-800",
        "hover:bg-green-800 dark:hover:bg-green-900",
        "hover:border-green-800 dark:hover:border-green-900",
      );
      sLabelColor = "text-white dark:text-gray-200";
      break;
    case BUTTON_CONFIG.CLASS.DANGER:
      aClassName.push(
        "bg-red-600 dark:bg-red-800",
        "border-red-600 dark:border-red-800",
        "hover:bg-red-800 dark:hover:bg-red-900",
        "hover:border-red-800 dark:hover:border-red-900",
      );
      sLabelColor = "text-white dark:text-gray-200";
      break;
    case BUTTON_CONFIG.CLASS.OUTLINE:
      aClassName.push(
        "bg-transparent",
        "border-transparent",
        "border-b-[1px]",
        "border-b-gray-700 dark:border-b-gray-200",
        "hover:border-[1px]",
        "hover:rounded-md hover:border-gray-200",
      );
      if (isActive)
        aClassName.push(
          "border-[1px]",
          "rounded-md border-gray-200",
        );
      else
        aClassName.push("rounded-none");
      sLabelColor = "text-gray-700 dark:text-gray-200";
      break;
    case BUTTON_CONFIG.CLASS.PRIMARY:
    default:
      aClassName.push("bg-primary-500", "border-yellow-800", "hover:bg-yellow-600", "hover:border-yellow-600");
      sLabelColor = "text-white";
      break;
  }

  const aLabelClassName = ["m-auto"];

  switch (nSize) {
    case BUTTON_CONFIG.SIZE.XS:
      aLabelClassName.push("text-xs");
      aClassName.push("py-1", "px-2");
      break;
    case BUTTON_CONFIG.SIZE.SM:
      aLabelClassName.push("text-sm");
      aClassName.push("py-2", "px-4");
      break;
    case BUTTON_CONFIG.SIZE.MD:
    default:
      aLabelClassName.push("text-base");
      aClassName.push("py-2", "px-6");
      break;
    case BUTTON_CONFIG.SIZE.LG:
      aLabelClassName.push("text-lg");
      aClassName.push("py-3", "px-7");
      break;
    case BUTTON_CONFIG.SIZE.XL:
      aLabelClassName.push("text-xl");
      aClassName.push("py-6", "px-12");
      break;
  }

  if (bIsFull) aClassName.push("w-full");

  if (bDisabled) aClassName.push("opacity-50", "cursor-not-allowed");

  aClassName.push(sCustomClassName);

  return (
    <div className={sContainerClassName}>
      <button className={`${aClassName.join(" ")}`} onClick={bDisabled ? undefined : onClick}>
        {oIcon && sIconPosition === BUTTON_CONFIG.POSITION.LEFT && oIcon}
        {sLabel ? (
          <Text color={sLabelColor} className={aLabelClassName.join(" ")}>
            {sLabel}
          </Text>
        ) : null}
        {oIcon && sIconPosition === BUTTON_CONFIG.POSITION.RIGHT && oIcon}
      </button>
    </div>
  );
};

export default Button;
