import oAxiosInstance from "config/Axios";

export const apiCallGetAllResguardos = oData => {
  return oAxiosInstance.post("resguardos/consultar", oData);
};

export const apiCallResguardosExportToCsv = oData => {
  return oAxiosInstance.post("resguardos/exportarcsv", oData);
};

export const apiCallResguardosFind = nId => {
  return oAxiosInstance.get(`resguardos/consultarCabezal/${nId}`);
};

export const apiCallResguardosFindLines = nId => {
  return oAxiosInstance.get(`resguardos/consultarLineas/${nId}`);
};

export const apiCallResguardosSave = oData => {
  return oAxiosInstance.post("resguardos", oData);
};

// export const apiCallResguardosUpdate = (nId, oData) => {
//   return oAxiosInstance.put(`resguardos/${nId}`, oData);
// };

export const apiCallResguardosDelete = nId => {
  return oAxiosInstance.delete(`resguardos/${nId}`);
};

export const apiCallSendResguardoToDGI = (nId, sType = "D") => {
  return oAxiosInstance.get(`efactura/reenviarcfe/${nId}/${sType}`);
};

export const apiCallResguardoDownload = nId => {
  return oAxiosInstance.get(`efactura/pdfcfe/${nId}`);
};

export const apiCallResguardoPrint = nId => {
  return oAxiosInstance.get(`resguardos/imprimir/${nId}`);
};

export const apiCallResguardoCancel = nId => {
  return oAxiosInstance.get(`resguardos/anular/${nId}`);
};

export const apiCallResguardoGetRelations = nId => {
  return oAxiosInstance.get(`resguardos/traerdocsrelacionados/${nId}`);
};

export const apiCallResguardoGetResguardosRelations = oData => {
  return oAxiosInstance.post("resguardos/buscardocsportipoynro", oData);
};

export const apiCallResguardoSaveRelations = oData => {
  return oAxiosInstance.post("resguardos/agregarelacion", oData);
};

export const apiCallResguardoDeleteRelations = oData => {
  return oAxiosInstance.post("resguardos/quitarelacion", oData);
};

export const apiCallResguardosGetLineInfo = oData => {
  return oAxiosInstance.post("resguardos/calcularlinea", oData);
};

export const apiCallResguardosGetTaxTypes = () => {
  return oAxiosInstance.get("resguardos/tiposretencion");
};
