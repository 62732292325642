import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaClipboardUser, FaRankingStar, FaToolbox } from "react-icons/fa6";
import { BiCog, BiFile, BiUserPin } from "react-icons/bi";
import { RiAdminFill } from "react-icons/ri";
import { IoIosListBox } from "react-icons/io";
import { BsCashCoin, BsClipboardCheck, BsReceipt } from "react-icons/bs";
import { ImPriceTags, ImUsers } from "react-icons/im";
import { AiOutlineOrderedList } from "react-icons/ai";
import { FiChevronsLeft, FiLogOut, FiMenu } from "react-icons/fi";
import { TbBoxMultiple } from "react-icons/tb";
import { MdFactory, MdInventory, MdLocationOn, MdLockOutline, MdManageAccounts, MdOutlinePointOfSale, MdSecurity, MdTask } from "react-icons/md";
import { FaHome, FaStore, FaBox, FaUsers, FaMoon, FaSun, FaBan, FaClipboardList } from "react-icons/fa";
import { LiaCashRegisterSolid } from "react-icons/lia";
import { CiBoxes } from "react-icons/ci";
import { HiOutlineReceiptRefund, HiOutlineReceiptTax } from "react-icons/hi";

import MenuDropDown from "./MenuDropDown";
import MenuItem from "./MenuItem";
import Text from "components/Text";
import Image from "components/Image";

import { actionGetManual } from "redux/auth/AuthActions";
import { actionAuthClean, actionLogout } from "redux/auth/AuthSlice";
import { selectLoguedUser, selectManualFile } from "redux/auth/AuthSelector";

import { CONSTANTS_COLORS } from "config/constants/Colors";

import Logo from "assets/images/png/logo.png";
import { SharedContext } from "context/SharedContext";
import { ReportTypes } from "pages/reports/constants/Reports";

const Menu = ({ open: bIsOpen, onChange }) => {
  const oDispatch = useDispatch();
  const { Logout, bDarkTheme, setDarkTheme } = useContext(SharedContext);
  const oUser = useSelector(selectLoguedUser);
  const sManual = useSelector(selectManualFile);

  const onLogout = () => {
    Logout();
    oDispatch(actionLogout());
  };

  const [sOpen, setOpen] = useState(null);

  const nIconSize = 22;
  const nIconSizeLg = 25;

  const downloadManual = () => oDispatch(actionGetManual());

  useEffect(() => {
    if (sManual) {
      const aBytes = atob(sManual);
      const aBytesNumber = new Array(aBytes.length);
      for (let nCount = 0; nCount < aBytes.length; nCount++)
        aBytesNumber[nCount] = aBytes.charCodeAt(nCount);
      const aBytesArray = new Uint8Array(aBytesNumber);
      const oBlob = new Blob([aBytesArray], { type: "application/pdf" });
      const oBlobURL = URL.createObjectURL(oBlob);
      window.open(oBlobURL, "_blank");
      //! Code that generates a label and downloads the file with a custom name, does not support viewing
      // let oTag = document.createElement("a");
      // document.body.appendChild(oTag);
      // oTag.style = "display: none";
      // oTag.href = oBlobURL;
      // oTag.download = "Manual de usuario.pdf";
      // oTag.target = "_blank";
      // oTag.click();
      // Removing blob after 10 minutes
      setTimeout(() => {
        window.URL.revokeObjectURL(oBlobURL);
      }, (10 * 60 * 1000));
      oDispatch(actionAuthClean());
    }
  }, [sManual]);

  return (
    <div className={"fixed h-screen transition-all duration-300 shadow-lg z-40 " + (bIsOpen ? "w-64" : "w-14")}>
      <div className="h-full flex flex-col bg-gray-50 dark:bg-gray-800">
        <div className={`${bIsOpen ? "flex" : ""} items-center mb-8 text-center bg-primary-500 dark:bg-primary-800 px-4 py-4`}>
          <span
            onClick={() => onChange(!bIsOpen)}
            className={`${bIsOpen ? "flex" : "hidden"} items-center pl-2.5 col-span-5 transition-all cursor-pointer`}
          >
            <div className="h-10 w-10 mr-3 bg-white rounded-lg overflow-hidden">
              {
                oUser?.config?.company?.image
                  ? <Image path={`data:image/png;base64, ${oUser?.config?.company?.image}`} />
                  : <Image path={Logo} />
              }
            </div>
            <div className="flex flex-col">
              <span className="self-center text-lg font-semibold whitespace-nowrap text-white">Bonsai WEB</span>
              <span className="text-left text-[10px] text-white">{oUser?.config?.company?.name}</span>
            </div>
          </span>
          {bIsOpen ? (
            <div className="h-10 w-10 text-center cursor-pointer">
              <FiChevronsLeft
                size={nIconSizeLg}
                className="text-white ml-auto h-full"
                onClick={() => onChange(!bIsOpen)}
              />
            </div>
          ) : (
            <div className="h-10 text-center cursor-pointer">
              <FiMenu size={nIconSizeLg} className="m-auto h-full text-white" onClick={() => onChange(!bIsOpen)} />
            </div>
          )}
        </div>
        <ul className="grow px-2 overflow-scroll scrollbar-hide">
          <MenuItem label="Inicio" path="/" onlyIcon={!bIsOpen} className="my-2">
            <FaHome size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
          </MenuItem>
          <MenuDropDown
            open={sOpen}
            title="Titulares"
            identifier="headlines"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<ImUsers size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Socios de negocio" path="/partners" onlyIcon={!bIsOpen} className="my-2">
                <FaStore size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Grupos" path="/groups/partners" onlyIcon={!bIsOpen} className="my-2">
                <ImUsers size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Vendedores" path="/sellers" onlyIcon={!bIsOpen} className="my-2">
                <FaClipboardUser size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Financiero"
            identifier="financial"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<LiaCashRegisterSolid size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Caja actual" path="/current/cash_register" onlyIcon={!bIsOpen} className="my-2">
                <BsCashCoin size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Caja" path="/cash_register" onlyIcon={!bIsOpen} className="my-2">
                <LiaCashRegisterSolid size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Articulos"
            identifier="articles"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<MdInventory size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Listado" path="/articles" onlyIcon={!bIsOpen} className="my-2">
                <FaBox size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Familias" path="/families" onlyIcon={!bIsOpen} className="my-2">
                <IoIosListBox size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Unidades" path="/units" onlyIcon={!bIsOpen} className="my-2">
                <AiOutlineOrderedList size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Lista de precios" path="/prices" onlyIcon={!bIsOpen} className="my-2">
                <ImPriceTags size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Documentos"
            identifier="documents"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<BsClipboardCheck size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Listado" path="/documents" onlyIcon={!bIsOpen} className="my-2">
                <BsClipboardCheck size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Recibos" path="/receipts" onlyIcon={!bIsOpen} className="my-2">
                <BsReceipt size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Resguardos" path="/resguardos" onlyIcon={!bIsOpen} className="my-2">
                <HiOutlineReceiptRefund size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Inventario"
            identifier="inventory"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<MdFactory size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Producciones" path="/productions" onlyIcon={!bIsOpen} className="my-2">
                <MdFactory size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Lotes" path="/lots" onlyIcon={!bIsOpen} className="my-2">
                <TbBoxMultiple size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Gestión"
            identifier="management"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<MdManageAccounts size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Tipos de documentos" path="/document/types" onlyIcon={!bIsOpen} className="my-2">
                <AiOutlineOrderedList size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Indicadores de facturación" path="/indicators" onlyIcon={!bIsOpen} className="my-2">
                <AiOutlineOrderedList size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Localidades" path="/locations" onlyIcon={!bIsOpen} className="my-2">
                <MdLocationOn size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Seguridad"
            identifier="security"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<MdSecurity size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Usuarios" path="/users" onlyIcon={!bIsOpen} className="my-2">
                <FaUsers size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Roles" path="/roles" onlyIcon={!bIsOpen} className="my-2">
                <RiAdminFill size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Tareas" path="/tasks" onlyIcon={!bIsOpen} className="my-2">
                <MdTask size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
          <MenuDropDown
            open={sOpen}
            title="Reportes"
            identifier="reports"
            onClick={setOpen}
            onlyIcon={!bIsOpen}
            icon={<BiFile size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />}
          >
            <>
              <MenuItem label="Ranking de Ventas" path={`/reports/${ReportTypes.SALES_RANKING}`} onlyIcon={!bIsOpen} className="my-2">
                <FaRankingStar size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Crédito pendiente" path={`/reports/${ReportTypes.PENDING_CREDIT}`} onlyIcon={!bIsOpen} className="my-2">
                <FaBan size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Inventario (Stock)" path={`/reports/${ReportTypes.STOCK}`} onlyIcon={!bIsOpen} className="my-2">
                <CiBoxes size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Saldo de socios" path={`/reports/${ReportTypes.PARTNERS_BALANCE}`} onlyIcon={!bIsOpen} className="my-2">
                <BsCashCoin size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Resumen de IVA" path={`/reports/${ReportTypes.TAX}`} onlyIcon={!bIsOpen} className="my-2">
                <HiOutlineReceiptTax size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Utilidades en un periodo" path={`/reports/${ReportTypes.UTILITIES}`} onlyIcon={!bIsOpen} className="my-2">
                <FaToolbox size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Última venta por cliente" path={`/reports/${ReportTypes.LAST_PURCHASE_BY_CLIENT}`} onlyIcon={!bIsOpen} className="my-2">
                <MdOutlinePointOfSale size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Ventas por vendedor" path={`/reports/${ReportTypes.SELLER}`} onlyIcon={!bIsOpen} className="my-2">
                <FaClipboardUser size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
              <MenuItem label="Socios por vendedor" path={`/reports/${ReportTypes.PARTNER_BY_SELLER}`} onlyIcon={!bIsOpen} className="my-2">
                <FaClipboardList size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
              </MenuItem>
            </>
          </MenuDropDown>
        </ul>
        <ul className="m-2 border-t border-t-gray-400 dark:border-t-white">
          <MenuItem
            className="p-2"
            onlyIcon={!bIsOpen}
            label="Manual de usuario"
            onClick={downloadManual}
          >
            <BiUserPin size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
          </MenuItem>
          <MenuItem label="Configuración" onlyIcon={!bIsOpen} path="/config" className="p-2">
            <BiCog size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
          </MenuItem>
          <MenuItem label="Cerrar sesión" onlyIcon={!bIsOpen} onClick={onLogout} className="p-2">
            <FiLogOut size={nIconSize} color={CONSTANTS_COLORS.COLORS.INHERIT} />
          </MenuItem>
        </ul>
        <div className={`w-full flex ${bIsOpen ? "" : "flex-wrap justify-center"} items-center gap-3 text-white ` + (bIsOpen ? "border-t-2 px-4 py-4" : "px-1 py-4")}>
          <div className={"align-middle ml-1 overflow-hidden " + (!bIsOpen && "hidden")}>
            <Text className="whitespace-pre text-ellipsis overflow-hidden">{oUser?.name}</Text>
            <Text color={"text-gray-400"} size={"text-xs"} className="whitespace-pre text-ellipsis overflow-hidden">
              {oUser?.username}
            </Text>
          </div>

          <NavLink
            to="/change-password"
            className={`${bIsOpen ? "ml-auto" : ""} bg-gray-200 dark:bg-gray-50 text-gray-500 dark:text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-200 focus:outline-none rounded-md text-sm p-2.5`}
          >
            <MdLockOutline className="w-5 h-5 fill-gray-800 dark:fill-gray-800" />
          </NavLink>
          <button
            type="button"
            onClick={() => setDarkTheme(!bDarkTheme)}
            className="bg-gray-800 dark:bg-gray-50 text-gray-500 dark:text-gray-400 hover:bg-gray-600 dark:hover:bg-gray-200 focus:outline-none rounded-md text-sm p-2.5"
          >
            <FaMoon className={`${bDarkTheme && "hidden"} w-5 h-5 fill-gray-50`} />
            <FaSun className={`${!bDarkTheme && "hidden"} w-5 h-5 fill-yellow-500`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Menu;
